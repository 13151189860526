import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {BaseWidget, Button, ButtonType, Dropdown, DropdownRequiredType, IBaseWidgetProps, Icon, IconSize} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import SendWorkOrderModal, {SendWorkOrderModalAction} from "@/components/SendWorkOrderModal/SendWorkOrderModal";
import GetAndCreateIfMissingContactPersonRequest from "@/models/server/requests/GetAndCreateIfMissingContactPersonRequest";
import GetAndCreateIfMissingContactPersonResponse from "@/models/server/responses/GetAndCreateIfMissingContactPersonResponse";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./SummaryWidget.module.scss";

interface ISummaryWidgetProps extends IBaseWidgetProps {
    workOrder: WorkOrderModel;
    getContactPersons(sender: IBaseComponent): Promise<User[]>;
    getAndCreateIfMissingContactPerson(sender: IBaseComponent, request: GetAndCreateIfMissingContactPersonRequest): Promise<GetAndCreateIfMissingContactPersonResponse>
    onChange(sender: IBaseComponent, workOrder: WorkOrderModel): Promise<void>;
}

export default class SummaryWidget extends BaseWidget<ISummaryWidgetProps, User[]> {
    private readonly _sendWorkOrderModalRef: React.RefObject<SendWorkOrderModal> = React.createRef();

    private async onSendWorkOrderModalCloseAsync(success: boolean): Promise<void> {
        if (success) {
            await this.props.onChange(this, this.workOrder);
            await this.reloadAsync();
        }
    }

    private get isEditable(): boolean {
        return (!WorkOrderModel.isApproverOrOrdererValid(this.workOrder.customerApprover));
    }

    private async onChangeAsync(item: User): Promise<void> {
        this.workOrder.customerApproverId = item.id;
        this.workOrder.customerApprover = item;

        await this.props.onChange(this, this.workOrder);

        if (this.isEditable) {
            await this.openSendWorkOrderModalAsync();
        }
    }

    protected async fetchDataAsync(): Promise<User[]> {
        return await this.props.getContactPersons(this);
    }

    protected getInnerClassName(): string {
        return styles.summaryWidget;
    }

    public get workOrder(): WorkOrderModel {
        return this.props.workOrder;
    }

    public get distance(): number {
        return (this.workOrder.distances || []).sum(item => item.value);
    }

    public get totalHours(): number {
        return this.normalHours + this.overtime50Hours + this.overtime100Hours;
    }

    public get normalHours(): number {
        return (this.workOrder.userSalaryHours || []).sum(item => item.normalHours);
    }

    public get overtime50Hours(): number {
        return (this.workOrder.userSalaryHours || []).sum(item => item.overtime50Hours);
    }

    public get overtime100Hours(): number {
        return (this.workOrder.userSalaryHours || []).sum(item => item.overtime100Hours);
    }

    public get completionDate(): Date {
        return this.workOrder.completionDate || Utility.today();
    }

    public isAsync(): boolean {
        return true;
    }

    private async openSendWorkOrderModalAsync(createNewContactPerson: boolean = false): Promise<void> {
        const action: SendWorkOrderModalAction = (createNewContactPerson)
            ? SendWorkOrderModalAction.CreateWorkOrderApprover
            : SendWorkOrderModalAction.EditWorkOrderApprover;

        await this._sendWorkOrderModalRef.current!.openAsync({
            workOrder: this.workOrder,
            action,
        });
    }

    protected renderContent(): React.ReactNode {

        const ordererName: string = (this.workOrder.customerOrderer)
            ? TransformProvider.toString(this.workOrder.customerOrderer)
            : Localizer.genericNoData;

        const approverEmail: string = (this.workOrder.customerApprover && this.workOrder.customerApprover.email)
            ? this.workOrder.customerApprover.email
            : Localizer.genericNoData;

        const approverPhone: string = (this.workOrder.customerApprover && this.workOrder.customerApprover.phone)
            ? this.workOrder.customerApprover.phone
            : Localizer.genericNoData;

        const workOrderDescription: string = (this.workOrder.description)
            ? this.workOrder.description
            : Localizer.genericNoData;

        return (

            <div className={styles.summaryWidgetContent}>
                <div className={styles.header}>
                    <span>{Localizer.summaryWidgetTitle}</span>
                    <span className={styles.date}>{"{0:dd.MM.yyyy}".format(this.completionDate)}</span>
                </div>

                <div className={styles.body}>

                    {
                        (this.state.data) &&
                        (
                            <React.Fragment>

                                {
                                    (this.workOrder.owner) &&
                                    (
                                        <React.Fragment>
                                            <span className={styles.constructionSiteName}>{this.workOrder.owner.name}</span>
                                            <div>
                                                <span>{Localizer.summaryWidgetReference}</span>
                                                <span>{(this.workOrder.invoiceReference) ? this.workOrder.invoiceReference : "-"}</span>
                                            </div>
                                            <span>{TransformProvider.toString(this.workOrder.owner.location)}</span>
                                        </React.Fragment>
                                    )
                                }

                                <div>
                                    <span>{Localizer.summaryWidgetOrderer}</span>
                                    <span className={(this.workOrder.customerOrderer) ? styles.data : styles.noDataText}>{ordererName}</span>
                                </div>

                                <span>{Localizer.summaryWidgetApprover}</span>

                                <div className={this.css("w-100", styles.col)}>
                                    <Dropdown required noSubtext autoGroupSelected
                                              id="customerApproverDropdown"
                                              className={styles.dropdown}
                                              requiredType={DropdownRequiredType.Restricted}
                                              items={this.state.data}
                                              nothingSelectedText={Localizer.summaryWidgetDropdownNothingSelected}
                                              selectedItem={this.workOrder.customerApprover || this.workOrder.customerApproverId || undefined}
                                              onChange={async (_, item) => await this.onChangeAsync(item!)}
                                    />
                                </div>

                            </React.Fragment>
                        )
                    }

                    {
                        (this.workOrder.customerApprover) &&
                        (
                            <React.Fragment>
                                <div>
                                    <Icon name="far envelope" size={IconSize.X2}/>
                                    <span className={(!this.workOrder.customerApprover.email) ? styles.noDataText : ""}>{approverEmail}</span>
                                </div>

                                <div>
                                    <Icon name="fas tty" size={IconSize.X2}/>
                                    <span className={(!this.workOrder.customerApprover.phone) ? styles.noDataText : ""}>{approverPhone}</span>
                                </div>

                            </React.Fragment>
                        )
                    }

                    <div>
                        <Button icon={{name: "fas plus"}} type={ButtonType.Orange} onClick={async () => await this.openSendWorkOrderModalAsync(true)}/>
                        <p>{Localizer.summaryWidgetButtonCreateApprover}</p>
                    </div>

                    {
                        (this.isEditable) && (
                            <div>
                                <Button icon={{name: "fas pen"}} type={ButtonType.Orange} onClick={async () => await this.openSendWorkOrderModalAsync()}/>
                                <p>{Localizer.summaryWidgetButtonEditApprover}</p>
                            </div>
                        )
                    }

                    <SendWorkOrderModal ref={this._sendWorkOrderModalRef}
                                        getContactPersons={this.props.getContactPersons}
                                        getAndCreateIfMissingContactPerson={this.props.getAndCreateIfMissingContactPerson}
                                        onClose={async (_, __, ___, success) => await this.onSendWorkOrderModalCloseAsync(success)}
                    />

                    <div>
                        <span>{Localizer.summaryWidgetWorkOrderName}</span>
                        <span className={styles.data}>{this.workOrder.name}</span>
                    </div>

                    <span>{Localizer.summaryWidgetWorkOrderDescription}</span>
                    <span className={(workOrderDescription) ? styles.data : styles.noDataText}>{workOrderDescription}</span>

                    <div>
                        <span>{this.toMultiLines(Localizer.summaryWidgetLabelsDistance.format(this.distance))}</span>
                    </div>

                    <div>
                        <span>{this.toMultiLines(Localizer.summaryWidgetLabelsHours.format(this.totalHours, this.normalHours, this.overtime50Hours, this.overtime100Hours))}</span>
                    </div>

                </div>
            </div>
        );
    }
};