import {PageRoute, BasePageDefinitions, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import ConstructionSiteNavigator from "@/pages/ConstructionSiteManagement/ConstructionSiteNavigator";
import {IWorkOrderParameters} from "@/pages/WorkOrder/WorkOrder";
import FormDefinition from "@/models/server/forms/FormDefinition";
import {IFormDefinitionParameters} from "@/pages/FormDefinition/FormDefinition";

export default class PageDefinitions extends BasePageDefinitions {

    protected async require(pageContainer: string, pageName: string): Promise<any> {
        return await require(`../pages/${pageContainer}${pageName}/${pageName}`);
    }

    constructor() {
        super();
        PageRouteProvider.sendParametersOnRedirect = true;
    }

    public static readonly logoutRouteName: string = "Logout";

    public static readonly dummyRouteName: string = "Dummy";

    public static readonly dummyRoute: PageRoute = new PageRoute(PageDefinitions.dummyRouteName);

    public static readonly accountRouteName: string = "Account";

    public static readonly loginRouteName: string = "Login";

    public static readonly loginRoute: PageRoute = new PageRoute(PageDefinitions.loginRouteName);

    public static readonly adminRouteName: string = "Admin";

    public static readonly adminRoute: PageRoute = new PageRoute(PageDefinitions.adminRouteName);

    public static readonly adminConsoleRouteName: string = "AdminConsole";

    public static readonly adminConsoleRoute: PageRoute = new PageRoute(PageDefinitions.adminConsoleRouteName);

    public static readonly employeesRouteName: string = "Employees";

    public static readonly employeesRoute: PageRoute = new PageRoute(PageDefinitions.employeesRouteName);

    public static readonly workOrdersRouteName: string = "WorkOrders";

    public static readonly workOrders: PageRoute = new PageRoute(PageDefinitions.workOrdersRouteName);

    public static readonly constructionSitesRouteName: string = "ConstructionSites";

    public static readonly constructionSitesRoute: PageRoute = new PageRoute(PageDefinitions.constructionSitesRouteName);

    public static readonly dashboardRouteName: string = "Dashboard";

    public static readonly dashboardRoute: PageRoute = new PageRoute(PageDefinitions.dashboardRouteName);

    public static readonly errorRouteName: string = "Error";

    public static readonly errorRoute: PageRoute = new PageRoute(PageDefinitions.errorRouteName);

    public static readonly contactSupportRouteName: string = "ContactSupport";

    public static readonly contactSupportRoute: PageRoute = new PageRoute(PageDefinitions.contactSupportRouteName);

    public static readonly offlineRouteName: string = "Offline";

    public static readonly offlineRoute: PageRoute = new PageRoute(PageDefinitions.offlineRouteName);

    public static readonly changePasswordRouteName: string = "ChangePassword";

    public static readonly changePasswordRoute: PageRoute = new PageRoute(PageDefinitions.changePasswordRouteName);

    public static readonly forgotPasswordRouteName: string = "ForgotPassword";

    public static readonly forgotPasswordRoute: PageRoute = new PageRoute(PageDefinitions.forgotPasswordRouteName);

    public static readonly constructionSiteManagementRouteName: string = "ConstructionSiteManagement";

    public static readonly constructionSiteManagementRoute: PageRoute = new PageRoute(PageDefinitions.constructionSiteManagementRouteName);

    public static readonly constructionSiteManagement: (constructionSiteId: string) => PageRoute = (constructionSiteId: string) => ConstructionSiteNavigator.managementRoute(constructionSiteId);

    public static readonly warehouseManagementRouteName: string = "WarehouseManagement";

    public static readonly warehouseManagement: (warehouseId: string) => PageRoute = (warehouseId: string) => new PageRoute(PageDefinitions.warehouseManagementRouteName, null, warehouseId);

    public static readonly warehousesRouteName: string = "Warehouses";

    public static readonly warehousesRoute: PageRoute = new PageRoute(PageDefinitions.warehousesRouteName);

    public static readonly resetPasswordRouteName: string = "ResetPassword";

    public static readonly resetPasswordRoute: PageRoute = new PageRoute(PageDefinitions.resetPasswordRouteName);

    public static readonly myWorkReportsRouteName: string = "MyWorkReports";

    public static readonly myWorkReportsRoute: PageRoute = new PageRoute(PageDefinitions.myWorkReportsRouteName);

    public static readonly myWorkReports: (workOrderId?: string | null, openConfirmationModal?: boolean) => PageRoute = (workOrderId?: string | null, openConfirmationModal?: boolean) => new PageRoute(PageDefinitions.myWorkReportsRouteName, null, workOrderId, openConfirmationModal);

    public static readonly homeRouteName: string = "Home";

    public static readonly homeRoute: PageRoute = new PageRoute(PageDefinitions.homeRouteName);

    public static readonly userManagementRouteName: string = "UserManagement";

    public static readonly userManagementRoute: PageRoute = new PageRoute(PageDefinitions.userManagementRouteName);

    public static readonly salesProductCatalogManagementRouteName: string = "CatalogManagement/SalesProductCatalog";

    public static readonly salesProductCatalogManagementRoute: PageRoute = new PageRoute(PageDefinitions.salesProductCatalogManagementRouteName);

    public static readonly rentalMassProductCatalogManagementRouteName: string = "CatalogManagement/RentalMassProductCatalog";

    public static readonly rentalMassProductCatalogManagementRoute: PageRoute = new PageRoute(PageDefinitions.rentalMassProductCatalogManagementRouteName);


    public static readonly workOrderTypeManagementRouteName: string = "WorkOrderTypeManagement";

    public static readonly workOrderTypeManagementRoute: PageRoute = new PageRoute(PageDefinitions.workOrderTypeManagementRouteName);

    public static readonly regionManagementRouteName: string = "RegionManagement";

    public static readonly regionManagementRoute: PageRoute = new PageRoute(PageDefinitions.regionManagementRouteName);

    public static readonly extraChargeTypeManagementRouteName: string = "ExtraChargeTypeManagement";

    public static readonly extraChargeTypeManagementRoute: PageRoute = new PageRoute(PageDefinitions.extraChargeTypeManagementRouteName);

    public static readonly adminNewsEditorRouteName: string = "AdminNewsEditor";

    public static readonly adminNewsEditorRoute: PageRoute = new PageRoute(PageDefinitions.adminNewsEditorRouteName);

    public static readonly globalNotificationSettingsRouteName: string = "GlobalNotificationSettings";

    public static readonly globalNotificationSettingsRoute: PageRoute = new PageRoute(PageDefinitions.globalNotificationSettingsRouteName);

    public static readonly workOrderRouteName: string = "WorkOrder";

    public static readonly formDefinitionsRouteName: string = "FormDefinitions";

    public static readonly formDefinitionsRoute: PageRoute = new PageRoute(PageDefinitions.formDefinitionsRouteName);

    public static readonly formDefinitionRouteName: string = "FormDefinition";

    public static readonly settingsRouteName: string = "Settings";
    
    public static readonly settingsRoute: PageRoute = new PageRoute(PageDefinitions.settingsRouteName);

    public static readonly formDefinition: (routeFormDefinitionId?: string | null, formDefinition?: FormDefinition | null) => PageRoute = (routeFormDefinitionId?: string | null, formDefinition?: FormDefinition | null) => new PageRoute(PageDefinitions.formDefinitionRouteName, null, routeFormDefinitionId, {formDefinition: formDefinition} as IFormDefinitionParameters);

    /**
     * @param workOrderId Id of the WorkOrder which to display on the page. If undefined, a new WorkOrder will be created
     * @param ownerId Id of Construction site or Warehouse
     * @returns Route to {@link WorkOrder} page
     */
    public static readonly workOrderRoute: (workOrderId?: string | null, ownerId?: string | null) => PageRoute = (workOrderId?: string | null, ownerId?: string | null) => new PageRoute(PageDefinitions.workOrderRouteName, null, workOrderId, {ownerId} as IWorkOrderParameters);

    public static readonly myFormsRouteName: string = "MyForms";

    public static readonly myFormsRoute: PageRoute = new PageRoute(PageDefinitions.myFormsRouteName);

    public static readonly rentaTasksRouteName: string = "RentaTasks/Dashboard";

    public static readonly rentaTasksRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksRouteName);

    public static readonly assignMountersRouteName: string = "RentaTasks/AssignMounters";

    public static readonly assignMountersRoute: PageRoute = new PageRoute(PageDefinitions.assignMountersRouteName);

    public static readonly managersRouteName: string = "RentaTasks/Managers";

    public static readonly managersRoute: PageRoute = new PageRoute(PageDefinitions.managersRouteName);

    public static readonly checkOutRouteName: string = "RentaTasks/CheckOut";

    public static readonly checkOutRoute: PageRoute = new PageRoute(PageDefinitions.checkOutRouteName);

    public static readonly hoursAndDistancesRouteName: string = "RentaTasks/HoursAndDistances";

    public static readonly hoursAndDistancesRoute: PageRoute = new PageRoute(PageDefinitions.hoursAndDistancesRouteName);

    public static readonly selectConstructionSiteRouteName: string = "RentaTasks/SelectConstructionSite";

    public static readonly selectConstructionSiteRoute: PageRoute = new PageRoute(PageDefinitions.selectConstructionSiteRouteName);

    public static readonly approveRouteName: string = "RentaTasks/Approve";

    public static readonly approveRoute: PageRoute = new PageRoute(PageDefinitions.approveRouteName);

    public static readonly previewDetailsRouteName: string = "RentaTasks/PreviewDetails";

    public static readonly previewDetailsRoute: PageRoute = new PageRoute(PageDefinitions.previewDetailsRouteName);

    public static readonly summaryRouteName: string = "RentaTasks/Summary";

    public static readonly summaryRoute: PageRoute = new PageRoute(PageDefinitions.summaryRouteName);

    public static readonly rentaTasksWorkOrdersRouteName: string = "RentaTasks/WorkOrders";

    public static readonly rentaTasksWorkOrdersRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksWorkOrdersRouteName);

    public static readonly rentaTasksWorkOrderRouteName: string = "RentaTasks/WorkOrder";

    public static readonly rentaTasksWorkOrder: (workOrderId: string) => PageRoute = (workOrderId: string) => new PageRoute(PageDefinitions.rentaTasksWorkOrderRouteName, null, workOrderId);

    public static readonly addEquipmentRouteName: string = "RentaTasks/AddEquipment";

    public static readonly addEquipmentRoute: PageRoute = new PageRoute(PageDefinitions.addEquipmentRouteName);

    public static readonly workOrderInfoRouteName: string = "RentaTasks/WorkOrderInfo";

    public static readonly workOrderInfoRoute: PageRoute = new PageRoute(PageDefinitions.workOrderInfoRouteName);

    public static readonly myWorkHoursRouteName: string = "RentaTasks/MyWorkHours";

    public static readonly myWorkHoursRoute: PageRoute = new PageRoute(PageDefinitions.myWorkHoursRouteName);

    /**
     * @param workOrderId Id of the Work Order which attachments to display.
     */
    public static readonly rentaTasksAttachmentsRoute = (workOrderId: string): PageRoute => new PageRoute("RentaTasks/Attachments", undefined, workOrderId);

    public static readonly rentaTasksWorkOrderFormsRouteName: string = "RentaTasks/WorkOrderForms";

    public static readonly rentaTasksWorkOrderForms: (workOrderId: string) => PageRoute = (workOrderId: string) => new PageRoute(PageDefinitions.rentaTasksWorkOrderFormsRouteName, null, workOrderId);

    public static readonly rentaTasksWorkOrderFormPageRouteName: string = "RentaTasks/WorkOrderForm";

    public static readonly rentaTasksWorkOrderFormPageRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksWorkOrderFormPageRouteName);

    public static readonly rentaTasksWorkOrderForm: (formId: string) => PageRoute = (formId: string) => new PageRoute(PageDefinitions.rentaTasksWorkOrderFormPageRouteName, null, formId);


    public static readonly rentaTasksFormPageRouteName: string = "RentaTasks/FormPage";

    public static readonly rentaTasksFormPageRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksFormPageRouteName);

    public static readonly rentaTasksFormSummaryPageRouteName: string = "RentaTasks/FormSummaryPage";

    public static readonly rentaTasksFormSummaryPageRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksFormSummaryPageRouteName);

    public static readonly rentaTasksApproveFormRouteName: string = "RentaTasks/ApproveForm";

    public static readonly rentaTasksApproveFormPageRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksApproveFormRouteName);

    public static readonly rentaTasksUsersSignaturesRouteName: string = "RentaTasks/MountersSignatures";

    public static readonly rentaTasksUsersSignaturesPageRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksUsersSignaturesRouteName);

    public static readonly rentaTasksFormReceiversRouteName: string = "RentaTasks/FormReceivers";

    public static readonly rentaTasksFormReceiversPageRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksFormReceiversRouteName);

    public static readonly rentaTasksFormPreviewPageRouteName: string = "RentaTasks/FormPreviewPage";

    public static readonly rentaTasksFormPreviewPageRoute: PageRoute = new PageRoute(PageDefinitions.rentaTasksFormPreviewPageRouteName);

    public static readonly rentaTasksSelectOrganizationRouteName: string = "RentaTasks/ActivateConstructionSite/SelectOrganization";

    public static readonly rentaTasksConfirmAddressRouteName: string = "RentaTasks/ActivateConstructionSite/ConfirmAddress";

    public static readonly rentaTasksSelectInactiveConstructionSiteRouteName: string = "RentaTasks/ActivateConstructionSite/SelectInactiveConstructionSite";

    public static readonly rentaTasksActivateConstructionSiteSummaryRouteName: string = "RentaTasks/ActivateConstructionSite/ActivateConstructionSiteSummary";

    /** Steps for "Activate construction site" wizard. */
    static ActivateConstructionSite = class {
        public static readonly SelectOrganizationRoute: PageRoute = new PageRoute(
            PageDefinitions.rentaTasksSelectOrganizationRouteName)

        public static readonly ConfirmAddressRoute: PageRoute = new PageRoute(
            PageDefinitions.rentaTasksConfirmAddressRouteName)

        public static readonly SelectSiteRoute: PageRoute = new PageRoute(
            PageDefinitions.rentaTasksSelectInactiveConstructionSiteRouteName)

        public static readonly SummaryRoute: PageRoute = new PageRoute(
            PageDefinitions.rentaTasksActivateConstructionSiteSummaryRouteName)
     }

    public static readonly notificationsWorkOrdersProcessedApprovalRouteName: string = "Notifications/WorkOrdersProcessedApproval";

    public static readonly notificationsWorkOrdersProcessedApprovalRoute: PageRoute = new PageRoute(PageDefinitions.notificationsWorkOrdersProcessedApprovalRouteName);

    public static readonly notificationsWorkOrdersPendingApprovalRouteName: string = "Notifications/WorkOrdersPendingApproval";

    public static readonly notificationsWorkOrdersPendingApprovalRoute: PageRoute = new PageRoute(PageDefinitions.notificationsWorkOrdersPendingApprovalRouteName);

    public static readonly rentaManagementRouteName: string = "RentaManagement/Dashboard";

    public static readonly rentaManagementRoute: PageRoute = new PageRoute(PageDefinitions.rentaManagementRouteName);

    public static readonly selectOrganizationRouteName: string = "RentaManagement/SelectOrganization";

    public static readonly selectOrganizationRoute: PageRoute = new PageRoute(PageDefinitions.selectOrganizationRouteName);

    public static readonly managementSelectConstructionSiteRouteName: string = "RentaManagement/SelectConstructionSite";

    public static readonly managementSelectConstructionSiteRoute: PageRoute = new PageRoute(PageDefinitions.managementSelectConstructionSiteRouteName);

    // #endregion
}

new PageDefinitions();