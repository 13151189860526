import "./BootstrapOverride.scss";
import React from "react";
import {IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, ch, IBaseComponent, Justify, PageCacheProvider, PageRoute, PageRouteProvider, TextAlign} from "@renta-apps/athenaeum-react-common";
import {CellAction, CellModel, ColumnActionType, ColumnDefinition, ColumnType, Dropdown, DropdownAlign, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@renta-apps/athenaeum-react-components";
import WorkOrderModel, {IWorkOrderEditability, WorkOrderAction} from "../../../models/server/WorkOrderModel";
import {ActionType} from "@/models/Enums";
import Product from "@/models/server/Product";
import WorkOrderDetailsPanel from "@/pages/WorkOrders/WorkOrderDetailsPanel/WorkOrderDetailsPanel";
import User from "@/models/server/User";
import UserContext from "@/models/server/UserContext";
import SendWorkOrderModal, {SendWorkOrderModalAction} from "@/components/SendWorkOrderModal/SendWorkOrderModal";
import PageDefinitions from "@/providers/PageDefinitions";
import {DefaultPrices} from "@/models/server/DefaultPrices";
import CompleteWorkOrderResponse from "@/models/server/responses/CompleteWorkOrderResponse";
import Comparator from "@/helpers/Comparator";
import ApproveWorkOrderModal from "@/pages/WorkOrders/WorkOrdersPanel/ApproveWorkOrderModal/ApproveWorkOrderModal";
import WorkOrdersToolbarModel from "@/pages/WorkOrders/WorkOrdersToolbar/WorkOrdersToolbarModel";
import FormModel from "@/models/server/forms/FormModel";
import GetWorkOrderFormsRequest from "@/models/server/requests/GetWorkOrderFormsRequest";
import FormModal from "@/pages/ConstructionSiteManagement/FormModal/FormModal";
import SaveFormRequest from "@/models/server/requests/SaveFormRequest";
import SaveDescriptionRequest from "@/models/server/requests/SaveDescriptionRequest";
import GetWorkOrdersRequest from "@/models/server/requests/GetWorkOrdersRequest";
import {ActivateWorkOrderRequest} from "@/models/server/requests/ActivateWorkOrderRequest";
import WorkOrderChangeStatusModal, {ChangeStatusAction} from "@/components/WorkOrderChangeStatusModal/WorkOrderChangeStatusModal";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import TransformProvider from "@/providers/TransformProvider";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import UserProvider from "@/providers/UserProvider";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "../../../localization/Localizer";
import UnleashHelper from "@/helpers/UnleashHelper";
import FeatureFlags from "@/helpers/FeatureFlags";
import ExternalNotificationModal from "@/components/ExternalNotificationModal/ExternalNotificationModal";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import SetInvoicedResponse from "@/models/server/responses/SetInvoicedResponse";
import CostPoolProvider from "@/providers/CostPoolProvider";

import styles from "./WorkOrdersPanel.module.scss";

export interface IWorkOrdersPanelProps {
    filters: WorkOrdersToolbarModel;
    defaultPrices: DefaultPrices;
    constructionSiteId?: string;
    readonly?: boolean;

    copyWorkOrder(newModel: WorkOrderModel): Promise<void>;
}

interface IWorkOrdersPanelState {
    products: Product[];
    mounters: User[];
}

export default class WorkOrdersPanel extends BaseComponent<IWorkOrdersPanelProps, IWorkOrdersPanelState> {

    state: IWorkOrdersPanelState = {
        products: [],
        mounters: []
    };

    private readonly _workOrdersGridRef: React.RefObject<Grid<WorkOrderModel>> = React.createRef();
    private readonly _sendWorkOrderModalRef: React.RefObject<SendWorkOrderModal> = React.createRef();
    private readonly _approveWorkOrderModalRef: React.RefObject<ApproveWorkOrderModal> = React.createRef();
    private readonly _formModalRef: React.RefObject<FormModal> = React.createRef();
    private readonly _changeStatusModalRef: React.RefObject<WorkOrderChangeStatusModal> = React.createRef();
    private readonly _externalNotificationModalRef: React.RefObject<ExternalNotificationModal> = React.createRef();

    private readonly _workOrdersColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: nameof<WorkOrderModel>(o => o.code),
            minWidth: 70,
            noWrap: true,
            className: "grey",
            sorting: true,
            init: (cell) => this.initCodeColumn(cell),
            actions: [
                {
                    title: Localizer.workOrdersShowDetailsTitleLanguageItemName,
                    type: ColumnActionType.Details,
                    callback: async (cell) => await this.toggleDetailsAsync(cell)
                }
            ]
        },
        {
            header: "fas sync-alt",
            minWidth: 42,
            type: ColumnType.Icon,
            textAlign: TextAlign.Center,
            accessor: (model) => WorkOrderModel.getStateIcon(model),
            sorting: true,
            name: "status"
        },
        {
            header: "fas ban",
            minWidth: 42,
            type: ColumnType.Icon,
            textAlign: TextAlign.Center,
            accessor: (model) => WorkOrderModel.getBlockingIcon(model, false),
            sorting: true,
            name: "blocked"
        },
        {
            header: Localizer.checkInsPanelSiteOrWarehouseLanguageItemName,
            accessor: "owner.contractName",
            init: (cell) => this.initOwnerColumn(cell),
            transform: (cell, value) => (cell.value) ? cell.value : "---",
            visible: !this.predefinedOwner,
            textAlign: TextAlign.Left,
            minWidth: 250,
            maxWidth: 250,
            sorting: true,
            settings: {
                infoAccessor: nameof<WorkOrderModel>(o => o.owner),
                infoTransform: (_, value) => (value) ? TransformProvider.constructionSiteOrWarehouseToString(value, true) : "",
                infoRoute: WorkOrdersPanel.getWorkOrderConstructionSiteRoute
            },
            actions: [
                {
                    name: nameof<WorkOrderModel>(o => o.name),
                    title: Localizer.genericOpenLanguageItemName,
                    icon: "fal external-link-square",
                    type: ActionType.Default,
                    callback: async (cell) => await this.triggerRouteAsync(cell.infoRoute)
                },
            ]
        },
        {
            header: Localizer.tasksNameLanguageItemName,
            accessor: nameof<WorkOrderModel>(o => o.name),
            type: ColumnType.Text,
            editable: true,
            reRenderRow: true,
            minWidth: 235,
            sorting: true,
            init: (cell) => this.initNameColumn(cell),
            route: WorkOrdersPanel.getWorkOrderRoute,
            settings: {
                descriptionAccessor: nameof<WorkOrderModel>(o => o.description),
                descriptionTitle: Localizer.invoicesInvoiceCommentLanguageItemName,
                descriptionJustify: Justify.Right,
                descriptionMaxLength: RentaTaskConstants.bigStringLength,
                descriptionCallback: (cell) => this.updateWorkOrderDescription(cell)
            },
            actions: [
                {
                    name: nameof<WorkOrderModel>(o => o.name),
                    title: Localizer.genericOpenLanguageItemName,
                    icon: "fal external-link-square",
                    type: ActionType.Default,
                    callback: async (cell) => await this.triggerRouteAsync(cell.route)
                },
            ]
        },
        {
            header: Localizer.tasksMountersLanguageItemName,
            accessor: nameof<WorkOrderModel>(o => o.mountersIds),
            type: ColumnType.Dropdown,
            settings: {
                fetchItems: async (cell: CellModel<WorkOrderModel>) => this.getAssignableMountersAsync(this.workOrdersGrid.instance, cell),
                align: DropdownAlign.Left,
                multiple: true,
                groupSelected: true,
                autoCollapse: true,
                nothingSelectedText: "0",
                selectedTextTransform: (dropdown: Dropdown<WorkOrderModel>) => dropdown.selectedListItems.length.toString(),
                descriptionAccessor: (model: WorkOrderModel) => WorkOrderModel.assignedMountersFullNames(model),
                descriptionJustify: Justify.Right,
                descriptionIcon: "fas info",
            },
            editable: true,
            minWidth: 75,
            init: (cell) => this.initMountersColumn(cell),
            callback: async (cell: CellModel<WorkOrderModel>) => await this.onWorkOrderMounterChangeAsync(cell)
        },
        {
            header: Localizer.workOrdersManagerLanguageItemName,
            accessor: nameof<WorkOrderModel>(o => o.manager),
            transform: (cell: CellModel<WorkOrderModel>) => TransformProvider.toString(cell.model.manager),
            type: ColumnType.Dropdown,
            sorting: true,
            settings: {
                fetchItems: async () => await UserProvider.getManagersAsync(this.workOrdersGrid.instance),
                groupSelected: true,
                align: DropdownAlign.Left,
                nothingSelectedText: "-"
            },
            editable: true,
            minWidth: 150,
            maxWidth: 150
        },
        {
            header: Localizer.genericCostPool,
            accessor: nameof<WorkOrderModel>(o => o.costPool),
            transform: (cell: CellModel<WorkOrderModel>) => TransformProvider.toString(cell.model.costPool),
            type: ColumnType.Dropdown,
            sorting: true,
            settings: {
                fetchItems: async () => await CostPoolProvider.getCostPoolsAsync(this.workOrdersGrid.instance),
                groupSelected: true,
                align: DropdownAlign.Left,
                nothingSelectedText: "-"
            },
            editable: true,
            minWidth: 150,
            maxWidth: 150
        },
        {
            header: Localizer.workOrdersCustomerOrdererLanguageItemName,
            accessor: nameof<WorkOrderModel>(o => o.customerOrderer),
            settings: {
                align: DropdownAlign.Left,
            },
            minWidth: 150,
            maxWidth: 150,
            sorting: true,
            noWrap: true,
            transform: (_, customerOrderer: User | null) => customerOrderer ? TransformProvider.toString(customerOrderer) : "-",
        },
        {
            header: Localizer.invoicesReferenceLanguageItemName,
            accessor: nameof<WorkOrderModel>(wo => wo.invoiceReference),
            maxWidth: 150,
            sorting: true,
        },
        {
            header: Localizer.tasksStartLanguageItemName,
            group: Localizer.tasksDateLanguageItemName,
            accessor: nameof<WorkOrderModel>(o => o.activationDate),
            type: ColumnType.Date,
            format: "D",
            editable: true,
            reRenderRow: true,
            textAlign: TextAlign.Left,
            minWidth: 110,
            isDefaultSorting: true,
            sorting: true,
            init: (cell) => this.initActivationDateColumn(cell),
            callback: (cell) => this.onActivationDateChangedAsync(cell),
            actions: [
                {
                    name: nameof<WorkOrderModel>(o => o.activationDate),
                    title: Localizer.workOrdersActionsRemoveActivationDate,
                    icon: "fal minus",
                    type: ActionType.Delete,
                    callback: async (cell) => await this.clearActivationDateAsync(cell)
                },
            ]
        },
        {
            header: Localizer.tasksDoneLanguageItemName,
            group: Localizer.tasksDateLanguageItemName,
            accessor: nameof<WorkOrderModel>(o => o.completionDate),
            type: ColumnType.Date,
            format: "D",
            editable: true,
            reRenderRow: true,
            textAlign: TextAlign.Left,
            minWidth: 90,
            sorting: true,
            init: (cell) => this.initCompletionDateColumn(cell),
            settings: {
                max: Utility.today()
            }
        },
        {
            header: Localizer.tasksPanelCostLanguageItemName,
            accessor: nameof<WorkOrderModel>(o => o.cost),
            format: "C",
            minWidth: 80,
            sorting: true,
            editable: false,
            visible: this.isBusinessManagerOrAdmin
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 170,
            removable: false,
            init: (cell) => this.initWorkOrderOperations(cell),
            actions: [
                {
                    name: "forms",
                    title: Localizer.workOrdersPanelWorkOrderFormsLanguageItemName,
                    icon: "far list-alt",
                    type: ActionType.Create,
                    callback: async (cell, action) => {
                        await this.processWorkOrderOperationAsync(cell, action)
                    }
                },
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "copy",
                    title: Localizer.tasksPanelCopyTaskTemplateLanguageItemName,
                    icon: "far copy",
                    type: ActionType.Default,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "complete",
                    title: Localizer.tasksPanelCompleteTaskLanguageItemName,
                    icon: "far check-circle",
                    type: ActionType.Default,
                    confirm: (cell) => Localizer.workOrderActionsConfirmCompletion.format(cell.model),
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "activate",
                    title: Localizer.tasksPanelActivateTaskLanguageItemName,
                    icon: "far play-circle",
                    type: ActionType.Default,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "lock",
                    title: Localizer.workOrdersActionsLockLanguageItemName,
                    icon: "far fa-thumbs-up",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "unlock",
                    title: Localizer.workOrderActionsUnlockLanguageItemName,
                    icon: "fas unlock-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "invoice",
                    title: Localizer.workOrdersActionsInvoiceLanguageItemName,
                    icon: "far file-invoice",
                    type: ActionType.Create,
                    confirm: Localizer.workOrderActionsConfirmInvoice,
                    right: true,
                    actions: this.getInvoiceActions(),
                    callback: async (cell, action, selectedAction) => this.processWorkOrderOperationAsync(cell, action, selectedAction)
                },
                {
                    name: "preview",
                    title: Localizer.tasksPanelPreviewWorkReportLanguageItemName,
                    type: ColumnActionType.Preview,
                    actions: this.getPreviewActions(),
                    callback: async (cell, action, selectedAction) => this.processWorkOrderOperationAsync(cell, action, selectedAction),
                },
                {
                    name: "notification",
                    title: Localizer.workOrdersActionsNotification,
                    icon: "far comments",
                    type: ActionType.Default,
                    callback: async (cell, action, selectedAction) => this.processWorkOrderOperationAsync(cell, action),
                },
                {
                    name: "delete",
                    title: Localizer.workOrdersActionsDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.taskHoursPanelRestoreDeletedHoursLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                },
                {
                    name: "send",
                    title: Localizer.workOrdersActionsSendLanguageItemName,
                    type: ActionType.Default,
                    icon: "far mail-bulk",
                    right: true,
                    toggleModal: SendWorkOrderModal.modalId
                },
                {
                    name: "cancelInvoice",
                    title: Localizer.workOrdersActionsUnInvoiceLanguageItemName,
                    icon: "far align-slash",
                    type: ActionType.Delete,
                    right: true,
                    confirm: Localizer.workOrderActionsConfirmUnInvoice,
                    callback: async (cell, action) => this.processWorkOrderOperationAsync(cell, action)
                }
            ]
        },
    ];

    private async toggleDetailsAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const spannedRows: RowModel<WorkOrderModel>[] = cell.spannedRows;
        const rowToExpand: RowModel<WorkOrderModel> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }

    public async initializeAsync(): Promise<void> {
        const products: Product[] = await RentaTasksController.getProductsAsync(this);
        const mounters: User[] = await RentaTasksController.getMountersAsync(this.workOrdersGrid.instance, new GetEmployeesRequest());
        await this.setState({products, mounters});
    }

    private static getWorkOrderConstructionSiteRoute(cell: CellModel<WorkOrderModel>): PageRoute {
        return (cell.model.constructionSiteId)
            ? PageDefinitions.constructionSiteManagement(cell.model.constructionSiteId)
            // Grid types do not allow the returned value to be null, but it has been like this forever.
            : null as any;
    }

    private static getWorkOrderRoute(cell: CellModel<WorkOrderModel>): PageRoute {
        return (!WorkOrderModel.isNew(cell.model))
            ? PageDefinitions.workOrderRoute(cell.model.id)
            // Grid types do not allow the returned value to be null, but it has been like this forever.
            : null as any;
    }

    private async getWorkOrdersAsync(
        pageNumber: number,
        pageSize: number,
        sortColumnName: string | null,
        sortDirection: SortDirection | null
    ): Promise<IPagedList<WorkOrderModel>> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkOrders.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkOrders.SortDirection");

        const request: GetWorkOrdersRequest = new GetWorkOrdersRequest();

        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.from = this.props.filters.from;
        request.to = this.props.filters.to;
        request.managerUserIds = this.props.filters.managers.map(manager => manager.id);
        request.mounterUserIds = this.props.filters.mounters.map(mounter => mounter.id);
        request.regionIds = this.props.filters.regions.map(region => region.id);
        request.taskStatuses = this.props.filters.taskStatusesFilter;
        request.notAssigned = this.props.filters.notAssigned;
        request.constructionSiteOrWarehouseId = this.constructionSiteId || null;
        request.managerCostPools =  UnleashHelper.isEnabled(FeatureFlags.CostPoolMounterAutoSelectInWorkOrdersPage)
            ? [] // Use only mounter filter
            : this.props.filters.managerCostPoolsFilter.map(pool => pool.name);
        request.search = this.props.filters.search;

        return await WorkOrderProvider.getWorkOrdersPagedListAsync(this.workOrdersGrid, request);
    }

    private async getWorkOrderFormsAsync(sender: IBaseComponent, request: GetWorkOrderFormsRequest): Promise<FormModel[]> {
        return await sender.postAsync("api/constructionSiteManagement/getWorkOrderForms", request);
    }

    private async getAssignableMountersAsync(sender: IBaseComponent, cell: CellModel<WorkOrderModel>): Promise<User[]> {
        const model: WorkOrderModel = cell.model;
        const mounters: User[] = await RentaTasksController.getMountersAsync(sender, new GetEmployeesRequest());

        return WorkOrderModel.getAssignableMounters(model, mounters);
    }

    private async saveFormAsync(sender: IBaseComponent, request: SaveFormRequest): Promise<void> {
        await sender.postAsync("api/constructionSiteManagement/saveForm", request);

        await ch.flyoutMessageAsync(Localizer.workOrdersPanelFlyoutMessageFormSaved);

        await this.reloadWorkOrdersAsync();
    }

    private initMountersColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        const readonly: boolean = (model.completed);
        const hasAssignedMounters: boolean = (model.mounters.length > 0);

        cell.title = WorkOrderModel.assignedMountersFullNames(model);
        cell.readonly = readonly;

        if (cell.descriptionAction) {
            cell.descriptionAction.readonly = true;
            cell.descriptionAction.visible = (readonly && hasAssignedMounters);
        }
    }

    private initNameColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;

        cell.title = (model.createdBy != null)
            ? TransformProvider.userToString(model.createdBy)
            : "?";

        cell.readonly = !WorkOrderModel.isNew(model)
        cell.descriptionReadonly = (cell.isReadonly);
    }

    private initCodeColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        const deleted: boolean = cell.row.deleted;

        const detailsAction: CellAction<WorkOrderModel> = cell.actions[0];

        detailsAction.visible = (!deleted) && (!!model.id);
    }

    private initOwnerColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;

        const managerInfo: string = (model.manager != null)
            ? TransformProvider.userToString(model.manager)
            : "?";
        const addressInfo: string = (model.owner && model.owner.location)
            ? TransformProvider.locationToString(model.owner.location)
            : "?";

        cell.column.settings.infoTitle = Localizer.get(Localizer.workDayPanelSiteOrWarehouseColumnTitle, managerInfo, addressInfo);
        cell.readonly = true;
    }

    private initActivationDateColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;

        cell.valid = ((model.completionDate == null) || ((model.activationDate != null) && (model.completionDate.valueOf() >= model.activationDate.valueOf())));

        const clearAction: CellAction<WorkOrderModel> = cell.actions[0];
        clearAction.visible = (!cell.isDeleted) && (!cell.isReadonly) && (!model.completed) && ((model.activationDate != null) && (!model.hasSalaryHours));
    }

    private async clearActivationDateAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const model: WorkOrderModel = cell.row.model;
        model.activationDate = null;

        await this.onActivationDateChangedAsync(cell);
        await cell.row.reRenderAsync();
    }

    private async clearComplitionDateAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const model: WorkOrderModel = cell.row.model;
        model.completionDate = null;

        await cell.row.reRenderAsync();
    }

    private async triggerRouteAsync(route: PageRoute | null): Promise<void> {
        if (route) {
            await PageRouteProvider.redirectAsync(route);
        }
    }

    private async onActivationDateChangedAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const model: WorkOrderModel = cell.row.model;
        const isActivationDateInFuture: boolean = ((model.activationDate != null) && (model.activationDate.valueOf() > Utility.today().valueOf()));
        const needClearCompletionDate: boolean = ((model.activationDate == null) || (isActivationDateInFuture)) ||
            (model.completionDate != null) && ((model.activationDate.valueOf() > model.completionDate.valueOf()));

        if (needClearCompletionDate) {
            await this.clearComplitionDateAsync(cell);
        }
    }

    private initCompletionDateColumn(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;
        const activationDateInFuture: boolean = ((model.activationDate != null) && (model.activationDate.valueOf() > Utility.today().valueOf()));
        const editability: IWorkOrderEditability = WorkOrderModel.getEditability(model);

        cell.column.settings.min = model.activationDate;
        cell.readonly = (!editability.editable) || (model.activationDate == null) || (activationDateInFuture) || (model.hasBlockingForms);
        cell.valid = ((model.completionDate == null) || ((model.activationDate != null) && (model.completionDate.valueOf() >= model.activationDate.valueOf())));
    }

    private getInvoiceActions(): string[] | undefined {
        // No options if order creation is disabled.
        if (!UnleashHelper.isEnabled(FeatureFlags.SendCreateOrderRequest)) {
            return undefined;
        }

        return [this.invoiceWithOrderLabel, this.invoiceWithoutOrderLabel];
    }

    private getPreviewActions(): string[] {
        let actions: string[] = [Localizer.tasksPanelPreviewWorkReportLanguageItemName];
        if (this.isBusinessManagerOrAdmin) {
            actions = [...actions, Localizer.invoicesPreviewInvoiceLanguageItemName]
            
            if (UnleashHelper.isEnabled(FeatureFlags.WorkReportWithComments)) {
                actions = [...actions, Localizer.workReportWithCommentsActionLabel];
            }
        }
        
        return actions;
    }

    private async onWorkOrderMounterChangeAsync(cell: CellModel<WorkOrderModel>): Promise<void> {
        const model: WorkOrderModel = cell.row.model;
        const mounters: any[] = (model.mountersIds as any[]);
        const mounterIds: string[] = mounters.map(item => (item.isEmployee || item.isSubcontractorMounter) ? ((item as User).id) : (item as string));
        cell.setValue(mounterIds);
        await cell.reloadAsync();
    }

    private initRow(row: RowModel<WorkOrderModel>): void {
        const model: WorkOrderModel = row.model;

        const editability: IWorkOrderEditability = WorkOrderModel.getEditability(model);

        row.className = WorkOrderModel.getBackgroundStyle(model);

        // Set work order row readonly if needed
        for (const cell of row.cells) {
            if (cell.accessor === "mileagePrice") {
                // Note: Mileage price should still be editable before work order is invoiced.
                // We can't set readonly on row-level because that can't be overriden for a single cell.
                cell.readonly = (!editability.pricesEditable);
            } else if (cell.accessor === "costPool") {
                // Cost pool should still be editable before work order is invoiced.
                cell.readonly = (!editability.costPoolEditable);
            }
            else {
                // Other cells are editable when work order is new, in progress or completed.
                cell.readonly = (!editability.editable);
            }
        }
    }

    private async updateWorkOrderDescription(cell: CellModel<WorkOrderModel>): Promise<void> {
        if (cell.model.id) {
            const model: WorkOrderModel = cell.model;

            const request: SaveDescriptionRequest = new SaveDescriptionRequest();
            request.description = model.description;
            request.id = model.id;

            await this.workOrdersGrid.postAsync("api/workOrders/saveWorkOrderDescription", request);
        }
    }

    private initWorkOrderOperations(cell: CellModel<WorkOrderModel>): void {
        const model: WorkOrderModel = cell.row.model;

        const operations: Record<WorkOrderAction, boolean> = WorkOrderModel.getActions(
            model,
            cell.row.modified,
            cell.row.deleted
        );

        const formAction: CellAction<WorkOrderModel> = cell.actions[0];
        const saveAction: CellAction<WorkOrderModel> = cell.actions[1];
        const cancelAction: CellAction<WorkOrderModel> = cell.actions[2];
        const copyTaskAction: CellAction<WorkOrderModel> = cell.actions[3];
        const completeAction: CellAction<WorkOrderModel> = cell.actions[4];
        const activateAction: CellAction<WorkOrderModel> = cell.actions[5];
        const lockAction: CellAction<WorkOrderModel> = cell.actions[6];
        const unLockAction: CellAction<WorkOrderModel> = cell.actions[7];
        const invoiceAction: CellAction<WorkOrderModel> = cell.actions[8];
        const previewAction: CellAction<WorkOrderModel> = cell.actions[9];
        const notificationAction: CellAction<WorkOrderModel> = cell.actions[10];
        const deleteAction: CellAction<WorkOrderModel> = cell.actions[11];
        const restoreAction: CellAction<WorkOrderModel> = cell.actions[12];
        const sendToCustomerAction: CellAction<WorkOrderModel> = cell.actions[13];
        const cancelInvoiceAction: CellAction<WorkOrderModel> = cell.actions[14];

        formAction.visible = operations.forms && this.predefinedOwner;
        saveAction.visible = operations.save;
        cancelAction.visible = operations.cancel;
        copyTaskAction.visible = operations.copy;
        completeAction.visible = operations.complete;
        activateAction.visible = operations.activate;
        deleteAction.visible = operations.delete;
        restoreAction.visible = operations.restore;
        previewAction.visible = operations.preview;
        notificationAction.visible = operations.notification;
        lockAction.visible = operations.lock;
        unLockAction.visible = operations.unlock;
        invoiceAction.visible = operations.invoice;
        sendToCustomerAction.visible = operations.send;
        cancelInvoiceAction.visible = operations.cancelInvoice;
    }

    private async processWorkOrderOperationAsync(
        cell: CellModel<WorkOrderModel>,
        action: CellAction<WorkOrderModel>,
        selectedAction?: string
    ): Promise<void> {
        const workOrder: WorkOrderModel = cell.model;

        const actionName: WorkOrderAction = action.action.name as WorkOrderAction;

        switch (actionName) {

            case "forms":
            const request = new GetWorkOrderFormsRequest();
            request.workOrderId = workOrder.id;

            const forms: FormModel[] = await this.getWorkOrderFormsAsync(this, request);

            if (forms && forms.length > 0) {
                await this.formModal.openAsync(workOrder, forms);
            } else {
                await ch.flyoutWarningAsync(Localizer.get(Localizer.workOrdersPanelFlyoutWarningTaskNotMappedToFormDefinition, workOrder.name));
            }
            break;

            case "save":
                cell.row.model = await WorkOrderProvider.saveWorkOrderAsync(cell.grid, workOrder);
                await cell.row.bindAsync();
                break;

            case "cancel":
                await cell.row.cancelAsync();
                break;

            case "copy":
                const workOrderCopy: WorkOrderModel = await WorkOrderProvider.copyWorkOrderAsync(cell.grid, workOrder);
                await this.props.copyWorkOrder(workOrderCopy);
                break;

            case "activate":
                if (WorkOrderModel.canReactivate(workOrder) && workOrder.mounters.length > 0) {
                    await this.changeStatusModal.openAsync(workOrder);
                } else {
                    const confirmationMessage: string = (WorkOrderModel.isApproved(cell.model)
                        ? Localizer.workOrderActionsConfirmCompletedActivation
                        : Localizer.workOrderActionsConfirmActivation.format(cell.model));

                    const confirmed: boolean = await ch.confirmAsync(confirmationMessage);

                    if (confirmed) {
                        await this.activateWorkOrderAsync(workOrder.id, false, null);
                    }
                }

                break;

            case "complete":
                const response: CompleteWorkOrderResponse = await WorkOrderProvider.completeWorkOrderAsync(cell.grid, workOrder);

                cell.row.model = response.workOrder!;

                await cell.row.bindAsync();
                break;

            case "lock":
                await this.approveWorkOrderModal.openAsync(workOrder);
                break;

            case "unlock":
                cell.row.model = await WorkOrderProvider.unlockWorkOrderAsync(cell.grid, workOrder);
                await cell.row.bindAsync();
                break;

            case "invoice":
                if (UnleashHelper.isEnabled(FeatureFlags.WorkOrderCostPool) && (!workOrder.costPool || workOrder.costPool.deleted)) {
                    await ch.flyoutErrorAsync(Localizer.genericWorkOrderRequiredCostPool);
                    break;
                }

                let createOrder: boolean = false;
                if (!UnleashHelper.isEnabled(FeatureFlags.SendCreateOrderRequest)) {
                    // No options if order creation is disabled.
                    createOrder = false;
                } else if (selectedAction === this.invoiceWithOrderLabel) {
                    createOrder = true;
                } else if (selectedAction === this.invoiceWithoutOrderLabel) {
                    createOrder = false;
                } else {
                        throw new Error(`Not supported action ${selectedAction}`);
                }
                
                const setInvoicedResponse: SetInvoicedResponse = await WorkOrderProvider.invoiceWorkOrderAsync(cell.grid, workOrder, createOrder);
                if (!setInvoicedResponse.success) {
                    await ch.flyoutErrorAsync(setInvoicedResponse.errorMessage!);
                }
                cell.row.model = setInvoicedResponse.workOrderModel ?? cell.row.model;
                await cell.row.bindAsync();
                break;

            case "cancelInvoice":
                cell.row.model = await WorkOrderProvider.uninvoiceWorkOrderAsync(cell.grid, workOrder);
                await cell.row.bindAsync();
                break;

            case "delete":
                const deletePermanently: boolean = (workOrder.id == "");

                if (deletePermanently) {
                    await cell.grid.deleteAsync(cell.row.index);
                } else {
                    await WorkOrderProvider.deleteWorkOrderAsync(cell.grid, workOrder);

                    await cell.row.setDeletedAsync(true);
                }
                break;

            case "restore":
                const restoreOnServer = (workOrder.id != "");

                if (restoreOnServer) {
                    await cell.grid.postAsync("api/workOrders/restoreWorkOrder", workOrder.id);
                }

                await cell.row.setDeletedAsync(false);
                break;

            case "preview":
                switch (selectedAction) {
                    case Localizer.tasksPanelPreviewWorkReportLanguageItemName:
                        await WorkOrderProvider.previewWorkOrderPdfAsync(cell.model);
                        break;
                    case Localizer.invoicesPreviewInvoiceLanguageItemName:
                        await WorkOrderProvider.previewWorkOrderPdfWithPricesAsync(cell.model);
                        break;
                    case Localizer.workReportWithCommentsActionLabel:
                        await WorkOrderProvider.previewWorkOrderPdfWithCommentsAsync(cell.model);
                        break;
                }
                break;
            case "notification":
                await this.externalNotificationModal.openAsync(ch.getUser(), workOrder.id, workOrder.mounters);
                break;
            default:
                throw new TypeError(`Unsupported action ${actionName}`)
        }
    }

    private async activateWorkOrderAsync(workOrderId: string, sendNotification: boolean, comment: string | null): Promise<void> {
        const request = new ActivateWorkOrderRequest();
        request.workOrderId = workOrderId;
        request.sendNotification = sendNotification;
        request.comment = comment;

        const workOrder: WorkOrderModel = await WorkOrderProvider.activateWorkOrderAsync(this, request);

        const workOrderRow: RowModel<WorkOrderModel> | null = this.workOrdersGrid.rows.find(row => Comparator.isEqual(row.model, workOrder)) || null;

        if (!workOrderRow) {
            return;
        }

        workOrderRow.model = workOrder;

        await workOrderRow.bindAsync();
    }

    private async onConfirmAsync(workOrderId: string, sendNotification: boolean, comment: string | null): Promise<void> {
        if (this._changeStatusModalRef.current) {
            await this.activateWorkOrderAsync(workOrderId, sendNotification, comment);
        }
    }

    private async approveWorkOrderAsync(workOrderId: string): Promise<void> {
        if (this._workOrdersGridRef.current) {
            const rowModel: RowModel<WorkOrderModel> | null = this._workOrdersGridRef.current.rows.filter(row => (row.model.id == workOrderId))[0];
            if (rowModel) {
                rowModel.model = await WorkOrderProvider.lockWorkOrderAsync(rowModel.grid, workOrderId);

                await rowModel.bindAsync();
            }
        }
    }

    private async onSendWorkOrderModalCloseAsync(
        workOrder: WorkOrderModel,
        action: SendWorkOrderModalAction,
        success: boolean
    ): Promise<void> {
        if (!success) {
            return;
        }

        const workOrderRow: RowModel<WorkOrderModel> | null = this.workOrdersGrid.rows.find(row => Comparator.isEqual(row.model, workOrder)) || null;

        if (!workOrderRow) {
            return;
        }

        if ((action == SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndApproveWorkOrder) || (action == SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndSendWorkOrder)) {
            // WO approver was possibly edited and WO was approved or sent to customer

            const message: string = (action == SendWorkOrderModalAction.SelectCreateEditWorkOrderApproverAndApproveWorkOrder)
                ? Localizer.workOrdersApprovedAlert
                : Localizer.rentaTasksSignatureAlert;

            await ch.flyoutMessageAsync(message);

            workOrderRow.model = workOrder;

            await workOrderRow.bindAsync();
        } else {
            // WO approver or orderer info was displayed OR New WO approver or orderer was created OR existing WO approver or orderer was edited

            await workOrderRow.setModelAsync(workOrder);
        }
    }

    private async onDetailsChangeAsync(workOrder: WorkOrderModel, updateBlockingForms: boolean): Promise<void> {
        const cost: number = WorkOrderModel.calcCost(workOrder, this.defaultPrices);
        const hasSalaryHours: boolean = WorkOrderModel.hasSalaryHours(workOrder);
        const costChanged: boolean = (cost != workOrder.cost);
        const salaryHoursChanged: boolean = (hasSalaryHours != workOrder.hasSalaryHours);

        const changed: boolean = (costChanged) || (salaryHoursChanged) || (updateBlockingForms);

        if (changed) {
            if (!updateBlockingForms && (costChanged || salaryHoursChanged)) {
                workOrder.cost = cost;
                workOrder.hasSalaryHours = hasSalaryHours;
            }

            const row: RowModel<WorkOrderModel> = this.workOrdersGrid.get(workOrder);
            await row.reRenderAsync();
        }
    }

    private get constructionSiteId(): string | null {
        return this.props.constructionSiteId || null;
    }

    private get predefinedOwner(): boolean {
        return !!this.props.constructionSiteId;
    }

    private get readonly(): boolean {
        return !!this.props.readonly;
    }

    private get defaultPrices(): DefaultPrices {
        return this.props.defaultPrices;
    }

    private get isBusinessManagerOrAdmin(): boolean {
        const context: UserContext = ch.getContext() as UserContext;
        return context.isBusinessManager || context.isAdmin;
    }

    private get approveWorkOrderModal(): ApproveWorkOrderModal {
        return this._approveWorkOrderModalRef.current!;
    }

    public get workOrdersGrid(): GridModel<WorkOrderModel> {
        return this._workOrdersGridRef.current!.model;
    }

    private get formModal(): FormModal {
        return this._formModalRef.current!;
    }

    private get changeStatusModal(): WorkOrderChangeStatusModal {
        return this._changeStatusModalRef.current!;
    }

    private get externalNotificationModal(): ExternalNotificationModal {
        return this._externalNotificationModalRef.current!;
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("activationDate", "WorkOrders.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrders.SortDirection");
    }

    public get hasNewRow(): boolean {
        return (this.workOrdersGrid.rows
            .some(
                row =>
                    (!row.deleted) && (WorkOrderModel.isNew(row.model))));
    }

    private get invoiceWithOrderLabel(): string {
        return ch.isFinland  ? Localizer.finlandWorkOrderInvoiceAction : Localizer.genericActionInvoice;
    }

    private get invoiceWithoutOrderLabel(): string {
        return ch.isFinland ? Localizer.finlandWorkOrderMarkAsInvoicedAction : Localizer.workOrderInvoiceWithoutCreatingOrder;
    }

    public async reloadWorkOrdersAsync(): Promise<void> {
        PageCacheProvider.clear();
        await this.workOrdersGrid?.reloadAsync();
    }

    public getTitle(): string {
        return Localizer.topNavWorkOrders;
    }

    private renderDetailsContent(row: RowModel<WorkOrderModel>) {
        const model: WorkOrderModel = row.model;
        return (
            <WorkOrderDetailsPanel key={`${row.key}_details`}
                                   workOrder={model}
                                   mounters={WorkOrderModel.getAssignableMounters(model, this.state.mounters)}
                                   products={this.state.products}
                                   defaultPrices={this.defaultPrices}
                                   onChange={async (updateBlockingForms: boolean) => await this.onDetailsChangeAsync(model, updateBlockingForms)}
            />
        );
    }

    public render(): React.ReactNode {
        return (
            <>
                <Grid id="workOrdersPanelGrid"
                      pagination autoToggle
                      ref={this._workOrdersGridRef}
                      className={this.css(styles.workDayTable)}
                      hovering={GridHoveringType.Row}
                      odd={GridOddType.None}
                      minWidth="auto"
                      noDataText={Localizer.workOrdersGridNoDataText}
                      readonly={this.readonly}
                      columns={this._workOrdersColumns}
                      initRow={(row) => this.initRow(row)}
                      renderDetails={(row) => this.renderDetailsContent(row)}
                      fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) => await this.getWorkOrdersAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                      defaultSortColumn={this.sortColumn}
                      defaultSortDirection={this.sortDirection}
                />

                <SendWorkOrderModal ref={this._sendWorkOrderModalRef}
                                    onClose={async (_, workOrder, action, success) => await this.onSendWorkOrderModalCloseAsync(workOrder, action, success)}
                />

                <ApproveWorkOrderModal ref={this._approveWorkOrderModalRef}
                                       onApproveAsync={async (workOrderId) => await this.approveWorkOrderAsync(workOrderId)}
                />

                <FormModal ref={this._formModalRef}
                           onSubmit={async (sender, request) => this.saveFormAsync(sender, request)}
                           reloadData={async () => this.reloadWorkOrdersAsync()}
                           getWorkOrderForms={async (sender, request) => this.getWorkOrderFormsAsync(sender, request)}
                />

                <WorkOrderChangeStatusModal notificationTrigger forceComment
                                            ref={this._changeStatusModalRef}
                                            action={ChangeStatusAction.Reactivate}
                                            onConfirmAsync={(workOrderId, sendNotification, comment) => this.onConfirmAsync(workOrderId, sendNotification, comment)}
                />

                <FeatureSwitch flagName={FeatureFlags.ExternalNotifications}>
                    <ExternalNotificationModal ref={this._externalNotificationModalRef}/>
                </FeatureSwitch>
            </>
        );
    }
}