import {BasePage, BasePageParameters, ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import UserContext from "../server/UserContext";
import User from "../server/User";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";
import {DefaultPrices} from "@/models/server/DefaultPrices";
import UnleashHelper from "@/helpers/UnleashHelper";

export default abstract class AuthorizedPage<TParams extends BasePageParameters = {}, TState = {}>
    extends BasePage<TParams, TState, UserContext> {

    protected get userContext(): Readonly<UserContext> {
        return ch.getContext() as UserContext;
    }

    protected get isAcceptedRegulations(): boolean {
        const user: User | null = ch.findUser();

        return (user != null)
            && (user.agreementAccepted)
            && (user.registrationAccepted);
    }

    protected  get defaultPrices(): DefaultPrices {
        return this.userContext.defaultPrices;
    }

    protected get isContactPerson(): boolean {
        return (this.userContext.isContactPerson);
    }

    protected get isManager(): boolean {
        return (this.userContext.isManager);
    }

    protected get isMounter(): boolean {
        return (this.userContext.isMounter);
    }

    protected get isBusinessManager(): boolean {
        return (this.userContext.isBusinessManager);
    }

    protected get isAdmin(): boolean {
        return (this.userContext.isAdmin);
    }

    protected get isSalesPerson(): boolean {
        return (this.userContext.isSalesPerson);
    }

    protected getUser(): User {
        return ch.getUser();
    }

    protected  getUserId(): string {
        return ch.getUserId();
    }

    protected get country(): string {
        return this.userContext.country;
    }

    protected get moneySign(): string {
        switch (this.country) {
            case "fi":
                return "€";
            case "se":
                return "kr";
            default:
                throw new Error(`Unsupported country ${this.country}`);
        }
    }

    public async initializeAsync(): Promise<void> {
        if (!this.isAuthorized) {
            await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute, true, true);
        }

        await super.initializeAsync();

        if (!this.isAcceptedRegulations) {
            await this.alertWarningAsync(Localizer.myAccountPageAcceptanceRequired);
        }

        // Update userId on authorized page load to set the userId after the user has logged in.
        // Updating the userId only once in `Login.loginAsync()` would be optimal, but sometimes it does not work, maybe because context does not get updated fast enough.
        // Here the context always contains the user details.
        UnleashHelper.updateClientUserId();
    }
}