import React from "react";
import AuthorizedPage from "../../../models/base/AuthorizedPage";
import PageDefinitions from "../../../providers/PageDefinitions";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {ITitleModel, PageContainer, RouteWidget, WidgetContainer} from "@renta-apps/athenaeum-react-components";
import {MounterPermission} from "@/models/Enums";
import RentaTasksController, {RentaTasksAction} from "../RentaTasksController";
import Localizer from "../../../localization/Localizer";
import rentaTaskStyles from "../../RentaTask.module.scss";
import NewsCarousel from "@/components/NewsCarousel/NewsCarousel";
import {NewsItem} from "@/models/server/NewsItem";

interface IDashboardProps  {
}

interface IDashboardState {
    newsItems: NewsItem[];
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {

    state: IDashboardState = {
        newsItems: [],
    };

    private async newWorkOrderAsync(): Promise<void> {
        await RentaTasksController.startActionAsync(RentaTasksAction.NewWorkOrder);
    }

    private async activateConstructionSiteAsync(): Promise<void> {
        await RentaTasksController.startActionAsync(RentaTasksAction.ActivateConstructionSite);
    }

    private get mounterContextWorkOrder(): WorkOrderModel | null {
        return ((RentaTasksController.isSignedIn) && (RentaTasksController.mounterContextWorkOrder))
            ? RentaTasksController.mounterContextWorkOrder
            : null;
    }

    /**
     * @see RentaTasksController.isSignedIn
     */
    private get isSignedIn(): boolean {
        return (!!this.mounterContextWorkOrder);
    }

    private get addTaskDescription(): string {
        return (this.isSignedIn)
            ? Localizer.dashboardCreateTaskForCurrentSiteDescription
            : Localizer.dashboardCreateTaskDescription;
    }

    public get title(): ITitleModel | undefined {
        return RentaTasksController.signInTitle;
    }

    private get canCreateWorkOrder(): boolean {
        return !this.userContext.isSubcontractorMounter || RentaTasksController.can(MounterPermission.CreateWorkOrder);
    }

    public async initializeAsync(): Promise<void> {
        await RentaTasksController.checkExpirationAsync();

        const newsItems: NewsItem[] = await this.postAsync("/api/Application/GetNewsItems");
        await this.setState({newsItems});
    }

    public getManual(): string {
        return Localizer.rentaTasksDashboardGetManual;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent={this.desktop}
                           className={rentaTaskStyles.pageContainer}
                           alertClassName={rentaTaskStyles.alert}
            >
                <WidgetContainer>
                    {
                        (this.mounterContextWorkOrder) &&
                        (
                            <RouteWidget wide
                                         icon={{name: "fas tools"}}
                                         label={Localizer.rentaTasksDashboardRouteWidgetMyWorkOrder}
                                         description={this.mounterContextWorkOrder.name!}
                                         route={() => PageDefinitions.rentaTasksWorkOrder(this.mounterContextWorkOrder?.id ?? "")}
                            />
                        )
                    }

                    <RouteWidget id="workOrdersWidget"
                                 icon={{name: "fal list-alt"}}
                                 label={Localizer.rentaTasksDashboardRouteWidgetWorkOrders}
                                 description={Localizer.rentaTasksDashboardRouteWidgetWorkOrdersDescription}
                                 route={PageDefinitions.rentaTasksWorkOrdersRoute}
                    />

                    {
                        (this.canCreateWorkOrder) &&
                        (
                            <RouteWidget id="addWorkOrderWidget"
                                         icon={{name: "fal plus-octagon"}}
                                         label={Localizer.addTaskModalAddTask}
                                         description={this.addTaskDescription}
                                         onClick={async () => await this.newWorkOrderAsync()}
                            />
                        )
                    }

                    <RouteWidget id="myHoursWidget"
                                 wide={this.canCreateWorkOrder}
                                 icon={{ name: "fas business-time" }}
                                 label={Localizer.myWorkHoursLabel}
                                 description={Localizer.myWorkHoursDescription}
                                 route={PageDefinitions.myWorkHoursRoute}
                    />

                    {
                        (this.isManager || this.isBusinessManager || this.isAdmin) &&
                        (
                            <RouteWidget id="activateConstructionSiteWidget"
                                         wide icon={{name: "fas lightbulb-on"}}
                                         label={Localizer.activateConstructionSiteLabel}
                                         description={Localizer.activateConstructionSiteDescription}
                                         onClick={async () => await this.activateConstructionSiteAsync()}
                            />
                        )
                    }

                    {
                        (!this.isContactPerson) &&
                        <div className={rentaTaskStyles.newsCarouselOuterContainer}>
                            <div className={rentaTaskStyles.newsCarouselContainer}>
                                <NewsCarousel className={rentaTaskStyles.newsCarousel}
                                              newsItems={this.state.newsItems}/>
                            </div>
                        </div>
                    }

                </WidgetContainer>


            </PageContainer>
        );
    }
}