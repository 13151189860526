import { SortDirection } from "@renta-apps/athenaeum-toolkit";
import { TaskStatusFilter } from "@/models/Enums";

export default class GetWorkOrdersRequest {

    public constructionSiteOrWarehouseId: string | null = null;

    public taskStatuses: TaskStatusFilter[] = [];

    public from: Date | null = null;

    public to: Date | null = null;

    public notAssigned: boolean = false;

    public managerUserIds: string[] = [];

    public mounterUserIds: string[] = [];

    public regionIds: string[] = [];

    public pageNumber: number = 1;

    public pageSize: number = 100;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    /**
     * Returns only work orders where the manager's cost pools match any of the given cost pools.
     * If null or empty, will not filter by cost pool.
     */
    public managerCostPools: string[] = [];

    /**
     * If defined, only those Work Orders which field(s) contain the search string will be displayed.
     */
    public search: string | null = null;
}