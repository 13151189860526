import WorkOrderEquipment from "../WorkOrderEquipment";
import User from "@/models/server/User";
import WorkOrderDistance from "@/models/server/WorkOrderDistance";
import {CustomerApprovalType, WorkOrderContractType} from "@/models/Enums";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import CostPool from "@/models/server/CostPool";

export default class SaveWorkOrderRequest {

    public workOrderId: string = "";

    public contractType: WorkOrderContractType = WorkOrderContractType.Normal;

    public invoiceReference: string | null = "";

    public activationDate: Date | null = null;

    public hoursPrice: number | null = null;

    public mileagePrice: number | null = null;
    
    public alarmJobPrice: number | null = null;

    public completionDate: Date | null = null;

    public plannedCompletionDate: Date | null = null;

    public pauseStartDate: Date | null = null;

    public pauseEndDate: Date | null = null;

    public mounters: string[] = [];

    public managerId: string | null = null;

    public salesPersonId: string | null = null;

    public customerOrderer: User | null = null;

    public customerApprover: User | null = null;
    
    public costPool: CostPool | null = null;

    public equipment: WorkOrderEquipment[] | null = null;

    public extraCharges: WorkOrderExtraCharge[] | null = null;

    public distances: WorkOrderDistance[] | null = null;

    public rentalItems: WorkOrderRentalItemModel[] | null = null;

    public approvalType: CustomerApprovalType = CustomerApprovalType.Email;

    public name: string = "";

    public description: string | null = null;

    public jobSummary: string | null = null;
    
    /** 
     * Total cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedCost: number = 0;

    /**
     * Products' cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedProductCost: number = 0;

    /**
     * Transportation cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedTransportationCost: number = 0;

    /**
     * Hours' cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedHoursCost: number = 0;
}