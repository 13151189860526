import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import Region from "@/models/server/Region";

/**
 * {@link Region} provider.
 */
class RegionsProvider {  
    
    public async getRegionsForUserAsync(): Promise<Region[]> {
        return ApiProvider.getAsync<Region[]>("/api/warehouse/getRegionsForUser", null);
    }
    
}

/**
 * {@link RegionsProvider} singleton.
 */
export default new RegionsProvider();