import React from "react";
import {ApiProvider, ch, IIsLoading, PageCacheProvider, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Button, CellModel, GridModel, IconSize, PageContainer, PageHeader, PageRow, RowModel, Tab, TabContainer} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ConstructionSiteOrWarehouseType, ConstructionSiteStatus} from "@/models/Enums";
import ConstructionSite from "../../models/server/ConstructionSite";
import User from "../../models/server/User";
import Organization from "../../models/server/Organization";
import Warehouse from "../../models/server/Warehouse";
import ConstructionSitePanel from "./ConstructionSitePanel/ConstructionSitePanel";
import SaveConstructionSiteRequest from "../../models/server/requests/SaveConstructionSiteRequest";
import SaveConstructionSiteResponse from "@/models/server/responses/SaveConstructionSiteResponse";
import GetConstructionSiteWithFiltersResponse from "@/models/server/responses/GetConstructionSiteWithFiltersResponse";
import PageDefinitions from "@/providers/PageDefinitions";
import ConstructionSiteNavigator from "@/pages/ConstructionSiteManagement/ConstructionSiteNavigator";
import WorkOrdersPanel from "@/pages/WorkOrders/WorkOrdersPanel/WorkOrdersPanel";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import {ActivateConstructionSiteRequest} from "@/models/server/requests/ActivateConstructionSiteRequest";
import FormsData from "@/pages/ConstructionSiteManagement/FormsData/FormsData";
import ConstructionSiteAttachmentsPanel from "@/pages/ConstructionSiteManagement/ConstructionSiteAttachmentsPanel/ConstructionSiteAttachmentsPanel";
import WorkOrdersToolbar from "@/pages/WorkOrders/WorkOrdersToolbar/WorkOrdersToolbar";
import WorkOrdersToolbarModel from "@/pages/WorkOrders/WorkOrdersToolbar/WorkOrdersToolbarModel";
import SubcontractorsPanel from "@/pages/ConstructionSiteManagement/SubcontractorsPanel/SubcontractorsPanel";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import UserProvider from "@/providers/UserProvider";
import TransformProvider from "../../providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import "./BootstrapOverride.scss";
import styles from "./ConstructionSiteManagement.module.scss";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import Region from "@/models/server/Region";
import RegionsProvider from "@/providers/RegionsProvider";

interface IConstructionSiteManagementProps {
}

interface IConstructionSiteManagementState {
    constructionSite: ConstructionSite | null;
    previous: ConstructionSite | null;
    next: ConstructionSite | null;

    /**
     * Manager users displayed in {@link WorkOrdersToolbar}.
     */
    managers: User[];

    /**
     * Mounter users displayed in {@link WorkOrdersToolbar}.
     */
    mounters: User[];
    
    /**
     * Regions displayed in {@link WorkOrdersToolbar}.
     */
    regions: Region[];
    manager: User | null;
    organization: Organization | null;
    salesPerson: User | null;
    warehouse: Warehouse | null;
    isLoading: boolean;
    filters: WorkOrdersToolbarModel;
}

export default class ConstructionSiteManagement
    extends AuthorizedPage<IConstructionSiteManagementProps, IConstructionSiteManagementState>
    implements IIsLoading {

    public state: IConstructionSiteManagementState = {
        constructionSite: null,
        previous: null,
        next: null,
        managers: [],
        mounters: [],
        regions: [],
        manager: null,
        organization: null,
        salesPerson: null,
        warehouse: null,
        isLoading: false,
        filters: new WorkOrdersToolbarModel(),
    };

    private readonly _workOrdersPanel: React.RefObject<WorkOrdersPanel> = React.createRef();
    private readonly _formsDataRef: React.RefObject<FormsData> = React.createRef();
    private readonly _constructionSitePanelRef: React.RefObject<ConstructionSitePanel> = React.createRef();

    public getTitle(): string {
        return Localizer.topNavConstructionSiteManagement;
    }

    public getManual(): string {
        return Localizer.constructionSiteManagementGetManual;
    }

    private get workOrdersPanel(): WorkOrdersPanel {
        return this._workOrdersPanel.current!;
    }

    private get workOrdersGrid(): GridModel<WorkOrderModel> {
        return this.workOrdersPanel.workOrdersGrid;
    }

    private get constructionSiteId(): string {
        return this.routeId!;
    }

    private get constructionSite(): ConstructionSite | null {
        return this.state.constructionSite;
    }

    private get readonly(): boolean {
        return (this.constructionSite == null) || (this.constructionSite.status == ConstructionSiteStatus.Inactive);
    }

    private get isSubcontractorManager(): boolean {
        return this.getUser().isSubcontractorManager;
    }

    private get title(): string {
        return (this.constructionSite)
            ? (this.constructionSite.organizationContract != null)
                ? "{0}, {1}".format(this.constructionSite.organizationContract.name, this.constructionSite.name)
                : this.constructionSite.name
            : Localizer.genericLoading;
    }

    private get subtitle(): string {
        return ((this.constructionSite) && (this.constructionSite.location)) ? TransformProvider.toString(this.constructionSite.location) : "";
    }

    private async reloadWorkOrdersAsync(): Promise<void> {
        PageCacheProvider.clear();
        await this.workOrdersGrid.reloadAsync();
    }

    private async onFiltersChange(filters: WorkOrdersToolbarModel): Promise<void> {
        await this.setState({ filters });
        await this.workOrdersGrid.reloadAsync();
    }

    private async onGetBulkPdf(filters: WorkOrdersToolbarModel): Promise<void> {
        await this.setSpinnerAsync(true);

        const workOrders: WorkOrderModel[] = this._workOrdersPanel.current!.workOrdersGrid.items;

        await WorkOrderProvider.previewBulkWorkOrderPdfAsync(this, workOrders, filters.bulkPdfReceiver);

        await this.setSpinnerAsync(false);
    }
    private async addWorkOrderAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.workOrderRoute(null, this.constructionSiteId));
    }

    private async copyWorkOrderAsync(newModel?: WorkOrderModel) {
        const constructionSite: ConstructionSite | null = this.constructionSite;
        const cantAdd: boolean = (this.workOrdersPanel.hasNewRow);

        if (cantAdd) {
            await this.workOrdersGrid.reloadAsync();
        }

        if (constructionSite) {

            if (!newModel) {
                newModel = new WorkOrderModel();
                newModel.owner = new ConstructionSiteOrWarehouse();
                newModel.owner.type = ConstructionSiteOrWarehouseType.ConstructionSite;
                newModel.owner.id = constructionSite.id;
                newModel.owner.name = constructionSite.name;
                newModel.owner.location = constructionSite.location;
                newModel.hoursPrice = constructionSite.hoursPrice || this.defaultPrices.defaultHoursPrice;
                newModel.mileagePrice = constructionSite.mileagePrice || this.defaultPrices.defaultMileagePrice;
                newModel.constructionSiteId = this.constructionSiteId;
                newModel.invoiceReference = constructionSite.invoiceReference;
            }

            const rows: RowModel<WorkOrderModel>[] = await this.workOrdersGrid.insertAsync(0, newModel!);
            const row: RowModel<WorkOrderModel> = rows[0];
            const nameCell: CellModel<WorkOrderModel> = row.get("name");
            await nameCell.editAsync();
        }
    }

    private getNavigationSiteRoute(constructionSite: ConstructionSite | null): PageRoute | null {
        return (constructionSite != null)
            ? PageDefinitions.constructionSiteManagement(constructionSite.id)
            : null;
    }

    private getNavigationSiteTitle(constructionSite: ConstructionSite | null): string {
        return (constructionSite != null)
            ? `${constructionSite.name}\n${TransformProvider.toString(constructionSite.location)}`
            : "";
    }

    public async onIsLoading(): Promise<void> {
        await this.setState({isLoading: ApiProvider.isLoading});
    }

    public async setConstructionSiteActiveAsync(sender: ConstructionSitePanel, request: ActivateConstructionSiteRequest, active: boolean): Promise<void> {
        const apiUrl: string = active
            ? "api/constructionSiteManagement/ActivateConstructionSite"
            : "api/constructionSiteManagement/DeactivateConstructionSite";

        let constructionSite: ConstructionSite;
        if (active) {
            constructionSite = await sender.postAsync(apiUrl, request);
        } else {
            constructionSite = await sender.postAsync(apiUrl, request.constructionSiteId);
        }


        await this.setState({constructionSite});
    }

    public async constructionSiteSubmitAsync(sender: ConstructionSitePanel, request: SaveConstructionSiteRequest): Promise<SaveConstructionSiteResponse> {
        const response: SaveConstructionSiteResponse = await sender.postAsync("api/constructionSiteManagement/saveConstructionSite", request);

        if (response.constructionSite != null) {
            const constructionSite: ConstructionSite = response.constructionSite;
            await this.setState({constructionSite});
            await ch.flyoutMessageAsync(Localizer.tasksPanelFlyoutChangesSaved);
        }

        return response;
    }

    public async componentDidMount(): Promise<void> {
        await super.componentDidMount();

        if (this.constructionSite == null) {
            const getConstructionSiteRequest: unknown = {
                constructionSiteId: this.constructionSiteId,
                filters: ConstructionSiteNavigator.filters,
            };

            const [getConstructionSiteResponse, managers, mounters, regions] = await Promise.all([
                this.postAsync("api/constructionSiteManagement/getConstructionSiteWithFilters", getConstructionSiteRequest) as Promise<GetConstructionSiteWithFiltersResponse>,
                UserProvider.getManagersAsync(this),
                UserProvider.getMountersAsync(this, new GetEmployeesRequest(this.constructionSiteId)),
                RegionsProvider.getRegionsForUserAsync(),
            ]);

            const constructionSite: ConstructionSite = getConstructionSiteResponse.constructionSite!;
            const previous: ConstructionSite | null = getConstructionSiteResponse.previous;
            const next: ConstructionSite | null = getConstructionSiteResponse.next;
            const index: number = getConstructionSiteResponse.index;
            const totalItemCount: number = getConstructionSiteResponse.totalItemCount;

            ConstructionSiteNavigator.index = index;
            ConstructionSiteNavigator.totalItemCount = totalItemCount;

            await this.setState({
                constructionSite,
                previous,
                next,
                managers,
                mounters,
                regions,
            });
        }
    }

    private renderTitle(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    ((ConstructionSiteNavigator.hasFilters) && (ConstructionSiteNavigator.totalItemCount > 0))
                        ?
                        (
                            <div className={styles.title}>

                                <Button className={styles.button}
                                        icon={{name: "fal caret-left", size: IconSize.Large}}
                                        title={this.getNavigationSiteTitle(this.state.previous)}
                                        route={this.getNavigationSiteRoute(this.state.previous) || undefined}
                                        disabled={this.state.isLoading || !this.state.previous}
                                />

                                <span className={styles.blue}>{(ConstructionSiteNavigator.index + 1)}/{ConstructionSiteNavigator.totalItemCount}</span>

                                <Button className={styles.button}
                                        icon={{name: "fal caret-right", size: IconSize.Large}}
                                        title={this.getNavigationSiteTitle(this.state.next)}
                                        route={this.getNavigationSiteRoute(this.state.next) || undefined}
                                        disabled={this.state.isLoading || !this.state.next}
                                />

                                <span>{this.title}</span>

                            </div>
                        )
                        : this.title
                }

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer scale className={styles.constructionSiteManagement}>

                <PageHeader title={() => this.renderTitle()} subtitle={this.subtitle} withTabs className={styles.pageHeader}/>

                <PageRow>
                    <div className="col">

                        {
                            (this.constructionSite) &&
                            (
                                <TabContainer id="constructionSiteManagementTabs">

                                    <Tab id="workOrders" title={Localizer.constructionSiteManagementTabsWorkOrders}>

                                        <div className={"w-fit-content"}>

                                            <WorkOrdersToolbar model={this.state.filters}
                                                               userContext={this.userContext}
                                                               readonly={this.readonly}
                                                               costPoolFilterEnabled={false}
                                                               managers={this.state.managers}
                                                               mounters={this.state.mounters}
                                                               regions={this.state.regions}
                                                               addWorkOrder={async () => this.addWorkOrderAsync()}
                                                               onChange={async (model) => this.onFiltersChange(model)}
                                                               onGetBulkPdf={async (model) => this.onGetBulkPdf(model)}
                                            />

                                            <WorkOrdersPanel ref={this._workOrdersPanel}
                                                             filters={this.state.filters}
                                                             defaultPrices={this.defaultPrices}
                                                             constructionSiteId={this.constructionSite.id}
                                                             copyWorkOrder={async (newModel) => this.copyWorkOrderAsync(newModel)}
                                                             readonly={this.readonly}
                                            />

                                        </div>

                                    </Tab>

                                    <Tab id="forms"
                                         title={Localizer.formsTabTitle}
                                    >
                                        <FormsData showFiltersToolbar
                                                   ref={this._formsDataRef}
                                                   constructionSiteId={this.constructionSiteId}
                                                   onUpdateWorkOrderBlockingForms={async () => this.reloadWorkOrdersAsync()}
                                        />
                                    </Tab>


                                    <Tab id="projectAttachments" title={Localizer.constructionSiteManagementTabsProjectAttachments}>

                                        <ConstructionSiteAttachmentsPanel constructionSiteId={this.constructionSiteId}
                                                                          readonly={this.readonly}
                                        />

                                    </Tab>

                                    <Tab id="contract" title={Localizer.constructionSiteManagementTabsContract}>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-flex flex-nowrap w-100 no-first-last-margin">

                                                    <ConstructionSitePanel ref={this._constructionSitePanelRef}
                                                                           constructionSite={this.constructionSite}
                                                                           readonly={this.readonly}
                                                                           defaultPrices={this.defaultPrices}
                                                                           submit={async (sender, request) => await this.constructionSiteSubmitAsync(sender, request)}
                                                                           setActiveAsync={async (sender, activateConstructionSiteRequest, active) => await this.setConstructionSiteActiveAsync(sender, activateConstructionSiteRequest, active)}
                                                    />

                                                </div>
                                            </div>

                                        </div>

                                    </Tab>

                                    {
                                        (!this.isSubcontractorManager) &&
                                        (
                                            <Tab id="subcontractors"
                                                 title={Localizer.subContractorsTitleLanguageItemName}
                                            >

                                                <div className="row">
                                                    <SubcontractorsPanel constructionSite={this.constructionSite}
                                                                         onChange={async () => this.reloadWorkOrdersAsync()}
                                                    />
                                                </div>

                                            </Tab>
                                        )
                                    }

                                </TabContainer>
                            )
                        }

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}