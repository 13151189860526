import React from "react";
import {ApiProvider, BaseComponent, ch, IResponseContainer, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {AddressHelper, Layout} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import FlagProvider from "@unleash/proxy-client-react";
import UnleashHelper from "@/helpers/UnleashHelper";
import logo from './renta-logo.png';
import GetInfoBarResponse from "@/models/server/responses/GetInfoBarResponse";

interface IAppState {
    showInfoBar: boolean,
    infoBarText: "",
}

class App extends BaseComponent {
    state: IAppState = {
        showInfoBar: false,
        infoBarText: "",
    }

    private _timer: number = -1;

    private get christmasLightsEnabled(): boolean {
        const today: Date = Utility.today();
        const isDecemberToday: boolean = today.getMonth() === 11;
        const endOfFirstDayOfNewYear: Date = new Date(new Date()
            .getFullYear() + 1, 0, 2)
            .addMilliseconds(-1);
        
        return (isDecemberToday && today <= endOfFirstDayOfNewYear);
    }
    
    public async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        await this.refreshInfoBarAsync();
    }

    private async refreshInfoBarAsync(): Promise<void> {
        try {
            const response: GetInfoBarResponse = await this.getInfoBarAsync();
            this.setState({
                showInfoBar: response.enabled,
                infoBarText: response.text,
            });
        } catch (e) {
            // Ignore error
        }
    }
    
    private async getInfoBarAsync(): Promise<GetInfoBarResponse> {
        const response = await fetch("api/application/getInfoBar", { method: "GET" });
        const result = JSON.parse(await response.text()) as IResponseContainer;
        return result.value as GetInfoBarResponse;
    }

    render(): React.ReactNode {
        return (
            <FlagProvider unleashClient={UnleashHelper.client} startClient={false}>
                <Layout christmasLightsEnabled={this.christmasLightsEnabled}
                        onLogoClick={RentaTasksController.onLogoClickAsync}
                        topNavLogo={logo}
                        footerLogo={logo}
                        tokenLogin={async (_, token) => {await this.tokenLoginAsync(token)}}
                        showTopNavInfo={this.state.showInfoBar}
                        topNavInfoText={this.state.infoBarText}
                />
            </FlagProvider>
        );
    }

    public async tokenLoginAsync(token: string): Promise<any> {
        await ApiProvider.invokeWithForcedSpinnerAsync(
            () => ApiProvider.postAsync("api/Application/TokenLogin", token));

        // Reload with an empty path to get rid of ?token= from the URL bar.
        const path: string | null = null;
        await PageRouteProvider.changeUrlWithoutReload(path);
    }
}

//Register Unleash
ch.registerInitializeCallback(async () => await UnleashHelper.initialize());
//Register initialize events
ch.registerInitializeCallback(async () => await AddressHelper.loadGoogleLibraryAsync());
ch.registerInitializeCallback(async () => await RentaTasksController.initializeAsync());
//Register authorize events
ch.registerAuthorizeCallback(async () => await UserInteractionDataStorage.onAuthorize());
ch.registerAuthorizeCallback(async () => await RentaTasksController.authorizeAsync());

export default App;