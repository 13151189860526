import User from "@/models/server/User";
import {PageCacheProvider} from "@renta-apps/athenaeum-react-common";
import {PostAsync} from "@/types/PostAsync";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";

/**
 * {@link User} provider
 */
class UserProvider {

    public async getManagersAsync(sender: PostAsync<null, User[]>): Promise<User[]> {
        const endpoint = "/api/workOrders/getManagers" as const;

        return await PageCacheProvider.getAsync(
            endpoint,
            () => sender.postAsync(endpoint, null)
        );
    }

    public async getSalesPersonsAsync(sender: PostAsync<null, User[]>): Promise<User[]> {
        const endpoint = "/api/workOrders/getSalesPersons" as const;

        return await PageCacheProvider.getAsync(
            endpoint,
            () => sender.postAsync(endpoint, null)
        );
    }

    public async getMountersAsync(sender: PostAsync<GetEmployeesRequest, User[]>, request: GetEmployeesRequest): Promise<User[]> {
        const endpoint = "api/workOrders/getMounters" as const;

        return await PageCacheProvider.getAsync(
            endpoint,
            () => sender.postAsync(endpoint, request)
        );
    }

    public async getContactPersonsAsync(sender: PostAsync<string, User[]>, constructionSiteOrWarehouseId: string): Promise<User[]> {
        return await sender.postAsync("/api/workOrders/getContactPersons", constructionSiteOrWarehouseId)
    }
}

/**
 * {@link UserProvider} singleton
 */
export default new UserProvider();