import React from "react";
import {
    Alert,
    Button,
    ButtonContainer,
    ButtonType,
    DateInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Form,
    FourColumns,
    NumberInput,
    NumberInputBehaviour,
    SelectListItem,
    Spinner,
    SwitchNullable,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import {AlertModel, AlertType, BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import UserNotificationSetting from "@/models/server/notification/UserNotificationSetting";
import User from "@/models/server/User";
import GetUserNotificationSettingRequest from "@/models/server/requests/GetUserNotificationSettingRequest";
import GlobalNotificationSetting from "@/models/server/notification/GlobalNotificationSetting";
import {NotificationInterval, NotificationType} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import GetUserNotificationSettingResponse from "@/models/server/responses/GetUserNotificationSettingResponse";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import UserContext from "@/models/server/UserContext";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./NotificationSettings.module.scss";

interface INotificationSettingsProps {
    user?: User | null;
}

interface INotificationSettingsState {
    notificationSetting: UserNotificationSetting | GlobalNotificationSetting;
    notificationType: NotificationType;
    isGlobalSettingEnabled: boolean;
}

export default class NotificationSettings extends BaseComponent<INotificationSettingsProps, INotificationSettingsState> {
    private readonly _notificationTypeKey: string = "filter-notification-Type";

    state: INotificationSettingsState = {
        notificationSetting: (this.user) ? new UserNotificationSetting() : new GlobalNotificationSetting(),
        notificationType: this.initializeNotificationType(),
        isGlobalSettingEnabled: false
    };

    private initializeNotificationType(): NotificationType {
        const defaultType: NotificationType = (this.isGlobal || this.userContext.isManager || this.userContext.isBusinessManager || this.userContext.isSalesPerson)
            ? NotificationType.WorkOrdersProcessedApproval
            : NotificationType.WorkOrderReactivation;

        return UserInteractionDataStorage.getFilters(defaultType, this._notificationTypeKey);
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private get isGlobal(): boolean {
        return this.user == null;
    }

    private get user(): User | null {
        return this.props.user || null;
    }

    private get notificationSetting(): UserNotificationSetting | GlobalNotificationSetting {
        return this.state.notificationSetting!;
    }

    private get isGlobalSettingEnabled(): boolean {
        return this.state.isGlobalSettingEnabled;
    }

    private get notificationTypes(): SelectListItem[] {
        return (this.isGlobal || !this.userContext.isMounter)
            ? EnumProvider.getNotificationTypeItems()
            : [
                EnumProvider.getNotificationTypeItem(NotificationType.WorkOrderReactivation),
                EnumProvider.getNotificationTypeItem(NotificationType.WorkOrderAssignment),
            ];
    }

    private get notificationIntervalTypes(): SelectListItem[] {
        switch (this.notificationType) {
            case NotificationType.WorkOrdersProcessedApproval:
                return EnumProvider.getNotificationIntervalItems();
            case NotificationType.WorkOrdersPendingApproval:
                return [EnumProvider.getNotificationIntervalItem(NotificationInterval.Daily), EnumProvider.getNotificationIntervalItem(NotificationInterval.Weekly)];
            case NotificationType.WorkOrderReactivation:
            case NotificationType.WorkOrderAssignment:
            case NotificationType.WorkOrderDeclined:
                return [EnumProvider.getNotificationIntervalItem(NotificationInterval.OnDemand)];
            default:
                throw new TypeError(`Invalid notification type: ${this.notificationType}`);
        }
    }

    private get sendTime(): Date | null {
        return (this.notificationSetting.interval !== NotificationInterval.OnDemand)
            ? this.notificationSetting.sendTime
            : null;
    }

    private get notificationType(): NotificationType {
        return this.state.notificationType;
    }

    private get notificationSettingTypeDescription(): string {
        switch (this.notificationType) {
            case NotificationType.WorkOrdersProcessedApproval:
                return Localizer.notificationSettingsNotificationTypeWorkOrdersProcessedApprovalDescription;
            case NotificationType.WorkOrdersPendingApproval:
                return Localizer.notificationSettingsNotificationTypeWorkOrdersPendingApprovalDescription;
            case NotificationType.WorkOrderReactivation:
                return Localizer.notificationSettingsNotificationTypeWorkOrderReactivationDescription;
            case NotificationType.WorkOrderAssignment:
                return Localizer.notificationSettingsNotificationTypeWorkOrderAssignmentDescription;
            case NotificationType.WorkOrderDeclined:
                return Localizer.notificationSettingsNotificationTypeWorkOrderDeclinedDescription;
            default:
                throw new Error(`Unsupported notification type ${this.notificationType}`);
        }
    }

    private get pendingDaysSettingsVisible(): boolean {
        return (this.isGlobal && this.notificationSetting.notificationType == NotificationType.WorkOrdersPendingApproval);
    }

    private get intervalSettingsVisible(): boolean {
        return ((!this.isGlobal) || (this.notificationSetting.notificationType == NotificationType.WorkOrdersPendingApproval));
    }

    public get alert(): AlertModel {
        const alertModel = new AlertModel();
        alertModel.message = Utility.format(Localizer.notificationSettingsAlertMessageGlobalSettingsDisabled, EnumProvider.getNotificationTypeText(this.notificationSetting.notificationType));
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Info;
        return alertModel;
    }

    private async onChangeNotificationTypeAsync(item: SelectListItem): Promise<void> {
        const notificationType: number = Number.parseInt(item.value);

        await this.setState({notificationType});

        UserInteractionDataStorage.setFilters(notificationType, this._notificationTypeKey);

        await this.getNotificationSettingAsync();
    }

    private async onChangeNotificationsEnabledAsync(enabled: boolean): Promise<void> {
        this.notificationSetting.enabled = enabled;

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async onChangeNotificationPausedFromAsync(time: Date): Promise<void> {
        this.notificationSetting.pausedFrom = time;

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async onChangeNotificationPausedToAsync(time: Date): Promise<void> {
        this.notificationSetting.pausedUntil = time;

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async onChangeNotificationMediaTypeAsync(item: SelectListItem): Promise<void> {
        this.notificationSetting.mediaType = Number.parseInt(item.value);

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async onChangeNotificationIntervalAsync(item: SelectListItem): Promise<void> {
        this.notificationSetting.interval = Number.parseInt(item.value);

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async onChangeNotificationSendTimeAsync(time: Date): Promise<void> {
        this.notificationSetting.sendTime = time;

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async onChangeNotificationWeeklyOccurrenceAsync(sender: Dropdown<SelectListItem>): Promise<void> {
        this.notificationSetting.weeklySendOccurrence = sender.selectedItems.map(item => parseInt(item.value));

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async onChangeNotificationPendingDaysAsync(value: number): Promise<void> {
        (this.notificationSetting as GlobalNotificationSetting).pendingDays = value;

        await this.setState({notificationSetting: this.notificationSetting});
    }

    private async saveNotificationSettingAsync() {
        if (this.isGlobal) {
            const globalNotificationSetting = this.notificationSetting as GlobalNotificationSetting;

            await this.postAsync("/api/Admin/saveGlobalNotificationSetting", globalNotificationSetting);

            await ch.flyoutMessageAsync(Localizer.notificationSettingsFlyoutMessageGlobalSettingSaved);
        } else {
            const userNotificationSetting = this.notificationSetting as UserNotificationSetting;
            userNotificationSetting.userId = this.user!.id;
            userNotificationSetting.user = this.user!;
            userNotificationSetting.custom = true;

            const userNotificationSettingResponse: UserNotificationSetting = await this.postAsync("/api/Account/saveUserNotificationSetting", userNotificationSetting);

            await this.setState({notificationSetting: userNotificationSettingResponse});

            await ch.flyoutMessageAsync(Localizer.notificationSettingsFlyoutMessageUserSettingSaved);
        }
    }

    private async getNotificationSettingAsync(): Promise<void> {
        if (this.isGlobal) {
            await this.getGlobalNotificationSettingAsync();
        } else {
            await this.getUserNotificationSettingAsync();
        }
    }

    private async getGlobalNotificationSettingAsync(): Promise<void> {
        const notificationSetting: GlobalNotificationSetting = await this.postAsync("/api/Admin/getGlobalNotificationSetting", this.notificationType);

        await this.setState({notificationSetting, notificationType: notificationSetting.notificationType});
    }

    private async getUserNotificationSettingAsync(): Promise<void> {
        const request = new GetUserNotificationSettingRequest();
        request.userId = this.user!.id;
        request.notificationType = this.notificationType;

        const response: GetUserNotificationSettingResponse = await this.postAsync("/api/Account/getUserNotificationSetting", request);
        const notificationSetting: UserNotificationSetting = response.userNotificationSetting;

        await this.setState({
            notificationSetting,
            notificationType: notificationSetting.notificationType,
            isGlobalSettingEnabled: response.isGlobalSettingEnabled
        });
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.getNotificationSettingAsync();
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.notificationSettings}>
                {
                    (this.isSpinning()) && <Spinner/>
                }

                {
                    (!this.isGlobal && !this.isGlobalSettingEnabled) &&
                    (
                        <div>
                            <Alert model={this.alert}/>
                        </div>
                    )
                }

                <div className={this.css(styles.mainSettings, (this.mobile) && styles.mobile)}>
                    {
                        (this.notificationTypes.length > 1) &&
                        (
                            <Dropdown required noWrap noFilter
                                      id={"notificationType"}
                                      minWidth={"15rem"}
                                      className={"mb-4"}
                                      align={DropdownAlign.Left}
                                      label={Localizer.notificationSettingsDropdownType}
                                      items={this.notificationTypes}
                                      selectedItem={EnumProvider.getNotificationTypeItem(this.notificationType)}
                                      onChange={(_, item) => this.onChangeNotificationTypeAsync(item!)}
                            />
                        )
                    }

                    <SwitchNullable excludeNull
                                    className={this.css(this.mobile && styles.enableSwitch)}
                                    id={"notificationEnabled"}
                                    value={this.notificationSetting.enabled}
                                    leftLabel={"Off"}
                                    rightLabel={"On"}
                                    onChange={async (_, checked) => await this.onChangeNotificationsEnabledAsync(checked!)}
                    />
                </div>

                <div className={"w-100"}>
                    <span className={"d-block"}>
                        {Localizer.notificationSettingsTextDescription}
                    </span>
                    <span>
                        {this.notificationSettingTypeDescription}
                    </span>

                    <hr/>
                </div>

                {
                    (this.notificationSetting) &&
                    (

                        <Form id="notificationSettingsForm"
                              onSubmit={() => this.saveNotificationSettingAsync()}
                        >

                            <TwoColumns>

                                <Dropdown required noFilter
                                          id={"notificationMediaType"}
                                          label={Localizer.notificationSettingsDropdownMediaType}
                                          items={EnumProvider.getNotificationMediaTypeItems()}
                                          selectedItem={EnumProvider.getNotificationMediaTypeItem(this.notificationSetting.mediaType)}
                                          onChange={async (_, item) => await this.onChangeNotificationMediaTypeAsync(item!)}
                                />

                                {
                                    (this.pendingDaysSettingsVisible) &&
                                    (
                                        <NumberInput required
                                                     id="pendingDays"
                                                     label={Localizer.notificationSettingsNumberInputPendingDays}
                                                     behaviour={NumberInputBehaviour.Restricted}
                                                     step={1}
                                                     min={1}
                                                     max={365}
                                                     value={(this.notificationSetting as GlobalNotificationSetting).pendingDays}
                                                     onChange={(_, value) => this.onChangeNotificationPendingDaysAsync(value)}
                                        />
                                    )
                                }

                            </TwoColumns>

                            {
                                (this.intervalSettingsVisible) &&
                                (
                                    <TwoColumns>

                                        {
                                            (this.notificationIntervalTypes.length > 1) &&
                                            (
                                                <Dropdown required noFilter
                                                          id={"notificationInterval"}
                                                          label={Localizer.notificationSettingsDropdownInterval}
                                                          items={this.notificationIntervalTypes}
                                                          selectedItem={EnumProvider.getNotificationIntervalItem(this.notificationSetting.interval)}
                                                          onChange={async (_, item) => await this.onChangeNotificationIntervalAsync(item!)}
                                                />
                                            )
                                        }

                                        {
                                            (this.notificationSetting.interval != NotificationInterval.OnDemand) &&
                                            (
                                                <DateInput showTime showOnlyTime rentaStyle
                                                           id={"notificationSendTime"}
                                                           className={styles.timePicker}
                                                           label={Localizer.notificationSettingsDateInputSendTime}
                                                           value={this.sendTime}
                                                           onChange={async (date) => await this.onChangeNotificationSendTimeAsync(date)}
                                                />
                                            )
                                        }

                                    </TwoColumns>
                                )
                            }

                            <TwoColumns>

                                {
                                    (this.notificationSetting.interval == NotificationInterval.Weekly) &&
                                    (
                                        <Dropdown required multiple noFilter
                                                  id={"notificationWeeklySendOccurrence"}
                                                  label={Localizer.notificationSettingsDropdownWeeklySendOccurrence}
                                                  orderBy={DropdownOrderBy.None}
                                                  filterMinLength={10}
                                                  disabled={this.notificationSetting.interval !== NotificationInterval.Weekly}
                                                  items={EnumProvider.getDayOfWeekItems()}
                                                  selectedItems={this.notificationSetting.weeklySendOccurrence}
                                                  onChange={async (sender) => await this.onChangeNotificationWeeklyOccurrenceAsync(sender)}
                                        />
                                    )
                                }

                            </TwoColumns>

                            <FourColumns>

                                <DateInput rentaStyle
                                           id={'notificationPausedFrom'}
                                           className={styles.timePicker}
                                           label={Localizer.notificationSettingsDateInputPausedFrom}
                                           maxDate={this.notificationSetting.pausedUntil}
                                           value={this.notificationSetting.pausedFrom}
                                           onChange={async (date) => await this.onChangeNotificationPausedFromAsync(date)}
                                />

                                <DateInput rentaStyle
                                           id={"notificationPausedUntil"}
                                           className={styles.timePicker}
                                           label={Localizer.notificationSettingsDateInputPausedTo}
                                           minDate={this.notificationSetting.pausedFrom}
                                           value={this.notificationSetting.pausedUntil}
                                           onChange={async (date) => await this.onChangeNotificationPausedToAsync(date)}
                                />

                            </FourColumns>

                            <ButtonContainer className={"mt-3"}>

                                {
                                    (this.isGlobal) &&
                                    (

                                        <Button id={"back"}
                                                type={ButtonType.Blue}
                                                route={PageDefinitions.adminRoute}
                                                label={Localizer.genericBack}
                                        />
                                    )
                                }

                                <Button submit
                                        id={"save"}
                                        type={ButtonType.Orange}
                                        label={Localizer.formSave}
                                        icon={{name: "far save"}}
                                />
                            </ButtonContainer>

                        </Form>
                    )
                }
            </div>
        );
    }
}