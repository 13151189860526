import SaveWorkOrderRequest from "@/models/server/requests/SaveWorkOrderRequest";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import CopyWorkOrderRequest from "@/models/server/requests/CopyWorkOrderRequest";
import CompleteWorkOrderResponse from "@/models/server/responses/CompleteWorkOrderResponse";
import {ch} from "@renta-apps/athenaeum-react-common";
import CreateWorkOrderRequest from "@/models/server/requests/CreateWorkOrderRequest";
import CompleteWorkOrderRequest from "@/models/server/requests/CompleteWorkOrderRequest";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import GetWorkOrdersRequest from "@/models/server/requests/GetWorkOrdersRequest";
import ApproveWorkOrderByEmailResponse from "@/models/server/responses/ApproveWorkOrderByEmailResponse";
import ApproveWorkOrderByEmailRequest from "@/models/server/requests/ApproveWorkOrderByEmailRequest";
import GetContactPersonWorkOrdersRequest from "@/models/server/requests/GetContactPersonWorkOrdersRequest";
import {ActivateWorkOrderRequest} from "@/models/server/requests/ActivateWorkOrderRequest";
import PauseWorkOrderRequest from "@/models/server/requests/PauseWorkOrderRequest";
import PauseWorkOrderResponse from "@/models/server/responses/PauseWorkOrderResponse";
import {PostAsync} from "@/types/PostAsync";
import Localizer from "@/localization/Localizer";
import throwIfFalsy from "@/functions/ThrowIfFalsy";
import SetInvoicedResponse from "@/models/server/responses/SetInvoicedResponse";
import GetBulkWorkOrderPdfRequest from "@/models/server/GetBulkWorkOrderPdfRequest";
import SendBulkWorkOrderPdfRequest from "@/models/server/SendBulkWorkOrderPdfRequest";
import UserContext from "@/models/server/UserContext";
import SetInvoicedRequest from "@/models/server/requests/SetInvoicedRequest";

/**
 * {@link WorkOrderModel} provider
 */
class WorkOrderProvider {

    public async saveWorkOrderAsync(
        sender: PostAsync<SaveWorkOrderRequest, WorkOrderModel>,
        workOrder: Readonly<WorkOrderModel>
    ): Promise<WorkOrderModel> {
        const isNew: boolean = WorkOrderModel.isNew(workOrder);

        const saveRequest: SaveWorkOrderRequest = new SaveWorkOrderRequest();

        saveRequest.workOrderId = workOrder.id;
        saveRequest.name = workOrder.name!;
        saveRequest.hoursPrice = workOrder.hoursPrice;
        saveRequest.mileagePrice = workOrder.mileagePrice;
        saveRequest.alarmJobPrice = workOrder.alarmJobPrice;
        saveRequest.description = workOrder.description;
        saveRequest.mounters = workOrder.mountersIds;
        saveRequest.managerId = workOrder.manager?.id || null;
        saveRequest.salesPersonId = workOrder.salesPerson?.id || null;
        saveRequest.invoiceReference = workOrder.invoiceReference;
        saveRequest.approvalType = workOrder.approvalType;
        saveRequest.customerApprover = workOrder.customerApprover;
        saveRequest.customerOrderer = workOrder.customerOrderer;
        saveRequest.activationDate = workOrder.activationDate;
        saveRequest.completionDate = workOrder.completionDate;
        saveRequest.plannedCompletionDate = workOrder.plannedCompletionDate;
        saveRequest.pauseStartDate = workOrder.pauseStartDate;
        saveRequest.pauseEndDate = workOrder.pauseEndDate;
        saveRequest.jobSummary = workOrder.jobSummary;
        saveRequest.fixedCost = workOrder.fixedCost;
        saveRequest.fixedHoursCost = workOrder.fixedHoursCost;
        saveRequest.fixedProductCost = workOrder.fixedProductCost;
        saveRequest.fixedTransportationCost = workOrder.fixedTransportationCost;
        saveRequest.contractType = workOrder.contractType;
        saveRequest.costPool = workOrder.costPool;

        if (isNew) {
            const createRequest = new CreateWorkOrderRequest(saveRequest);

            createRequest.workOrderTypeId = workOrder.type!.id;
            createRequest.constructionSiteOrWarehouseId = workOrder.owner!.id;

            return await sender.postAsync("/api/workOrders/createWorkOrder", createRequest);
        }
        else {
            return await sender.postAsync("/api/workOrders/saveWorkOrder", saveRequest);
        }
    }

    public async copyWorkOrderAsync(sender: PostAsync<CopyWorkOrderRequest, WorkOrderModel>, workOrder: Readonly<WorkOrderModel>): Promise<WorkOrderModel> {
        const copyRequest: CopyWorkOrderRequest = new CopyWorkOrderRequest();

        copyRequest.sourceWorkOrderId = workOrder.id;

        return await sender.postAsync("/api/workOrders/copyWorkOrder", copyRequest);
    }

    public async getWorkOrdersPagedListAsync(sender: PostAsync<GetWorkOrdersRequest, IPagedList<WorkOrderModel>>, request: Readonly<GetWorkOrdersRequest>): Promise<IPagedList<WorkOrderModel>> {
        return await sender.postAsync("/api/workOrders/getWorkOrdersPagedList", request);
    }

    public async getContactPersonWorkOrdersPagedListAsync(sender: PostAsync<GetContactPersonWorkOrdersRequest, IPagedList<WorkOrderModel>>, request: Readonly<GetContactPersonWorkOrdersRequest>): Promise<IPagedList<WorkOrderModel>> {
        return await sender.postAsync("/api/myWorkOrders/getContactPersonWorkOrdersPagedList", request)
    }

    public async getWorkOrderAsync(sender: PostAsync<GetWorkOrderRequest, WorkOrderModel>, request: Readonly<GetWorkOrderRequest>): Promise<WorkOrderModel> {
        throwIfFalsy(request, nameof(request));

        const workOrder: WorkOrderModel = await sender.postAsync("/api/rentaTasks/getWorkOrder", request);

        throwIfFalsy(workOrder, nameof(workOrder));

        return workOrder;
    }

    public async previewWorkOrderPdfAsync(workOrder: Readonly<WorkOrderModel>): Promise<void> {
        const workOrderId: string = workOrder.id;

        await ch.documentPreviewAsync(
            "/api/workOrders/getWorkOrderPdf",
            workOrderId,
            Localizer.documentPreviewModalWorkReportTitle
        );
    }
    
    public async previewWorkOrderPdfWithPricesAsync(workOrder: Readonly<WorkOrderModel>): Promise<void> {
        const workOrderId: string = workOrder.id;

        await ch.documentPreviewAsync(
            "/api/workOrders/getWorkOrderPdfWithPrices",
            workOrderId,
            Localizer.documentPreviewModalWorkReportTitle
        );
    }

    public async previewWorkOrderPdfWithCommentsAsync(workOrder: Readonly<WorkOrderModel>): Promise<void> {
        const workOrderId: string = workOrder.id;

        await ch.documentPreviewAsync(
            "/api/workOrders/getWorkOrderPdfWithComments",
            workOrderId,
            Localizer.documentPreviewModalWorkReportTitle
        );
    }

    public async previewBulkWorkOrderPdfAsync(
        sender: PostAsync<SendBulkWorkOrderPdfRequest, any>,
        workOrders: Readonly<WorkOrderModel[]>,
        receiverEmail: string | null = null): Promise<void> {
        const workOrderIds: string[] = workOrders.map(workOrder => workOrder.id);

        const context: UserContext = ch.getContext() as UserContext;
        const maxCountExceeded: boolean = workOrders.length > context.maxSynchronousPdfCount;
        const hasAdditionalReceiver: boolean = !!receiverEmail && receiverEmail.trim().length > 0;
        if (maxCountExceeded || hasAdditionalReceiver) {
            const label: string = hasAdditionalReceiver 
                ? Localizer.workReportWithCommentsAdditionalReceiverConfirmation
                : Localizer.workReportWithCommentsConfirmation;
            const confirmed: boolean = await ch.confirmAsync(label);
            if (confirmed) {
                const sendRequest: SendBulkWorkOrderPdfRequest = {
                    workOrderIds: workOrderIds,
                    receiverEmail: receiverEmail,
                };
                await sender.postAsync("/api/workOrders/SendBulkWorkOrderPdf", sendRequest)
            }

            // Not confirmed so don't do anything.
            return;
        }
        
        const request: GetBulkWorkOrderPdfRequest = {
            workOrderIds: workOrderIds,
        };

        await ch.documentPreviewAsync(
            "/api/workOrders/GetBulkWorkOrderPdf",
            request,
            Localizer.documentPreviewModalWorkReportTitle
        );
    }

    public async activateWorkOrderAsync(sender: PostAsync<ActivateWorkOrderRequest, WorkOrderModel>, request: Readonly<ActivateWorkOrderRequest>): Promise<WorkOrderModel> {
        return await sender.postAsync("/api/workOrders/activateWorkOrder", request);
    }

    public async completeWorkOrderAsync(sender: PostAsync<CompleteWorkOrderRequest, CompleteWorkOrderResponse>, workOrder: Readonly<WorkOrderModel>): Promise<CompleteWorkOrderResponse> {
        const request: CompleteWorkOrderRequest = new CompleteWorkOrderRequest(workOrder.id);

        return await sender.postAsync("/api/workOrders/completeWorkOrder", request);
    }

    public async lockWorkOrderAsync(sender: PostAsync<string, WorkOrderModel>, workOrderId: string): Promise<WorkOrderModel> {
        return await sender.postAsync("/api/workOrders/managerApproveWorkOrder", workOrderId);
    }

    public async unlockWorkOrderAsync(sender: PostAsync<string, WorkOrderModel>, workOrder: Readonly<WorkOrderModel>): Promise<WorkOrderModel> {
        return await sender.postAsync("/api/workOrders/setWorkOrderNotReadyForInvoice", workOrder.id);
    }

    public async invoiceWorkOrderAsync(
        sender: PostAsync<SetInvoicedRequest, SetInvoicedResponse>,
        workOrder: Readonly<WorkOrderModel>,
        createOrder: boolean): Promise<SetInvoicedResponse> {

        const request: SetInvoicedRequest = {
            workOrderId: workOrder.id,
            createOrder: createOrder,
        };
        return await sender.postAsync("/api/workOrders/setInvoiced", request);
    }

    public async uninvoiceWorkOrderAsync(sender: PostAsync<string, WorkOrderModel>, workOrder: Readonly<WorkOrderModel>): Promise<WorkOrderModel> {
        return await sender.postAsync("/api/workOrders/unInvoice", workOrder.id)
    }

    public async approveWorkOrderByEmailAsync(sender: PostAsync<ApproveWorkOrderByEmailRequest, ApproveWorkOrderByEmailResponse>, request: Readonly<ApproveWorkOrderByEmailRequest>): Promise<ApproveWorkOrderByEmailResponse> {
        return await sender.postAsync("/api/workOrders/approveWorkOrderByEmail", request);
    }

    public async approveSentWorkOrderAsync(sender: PostAsync<string, void>, workOrderId: string): Promise<void> {
        await sender.postAsync("/api/workOrders/approveSentWorkOrder", workOrderId);
    }

    public async pauseWorkOrderAsync(sender: PostAsync<PauseWorkOrderRequest, PauseWorkOrderResponse>, request: Readonly<PauseWorkOrderRequest>): Promise<PauseWorkOrderResponse> {
        return await sender.postAsync("/api/workOrders/pauseWorkOrder", request);
    }

    public async deleteWorkOrderAsync(sender: PostAsync<string, never>, workOrder: Readonly<WorkOrderModel>): Promise<void> {
        await sender.postAsync("/api/workOrders/deleteWorkOrder", workOrder.id);
    }
}

/**
 * {@link WorkOrderProvider} singleton
 */
export default new WorkOrderProvider();