import React from "react";
import {
    Button,
    ButtonContainer,
    ButtonType,
    CellModel,
    ColumnDefinition,
    ColumnType,
    Form,
    Grid,
    PageContainer,
    PageHeader,
    PageRow,
    Spinner,
    Tab,
    TabContainer,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Setting from "../../models/server/Setting";
import OrganizationContract from "../../models/server/OrganizationContract";
import FeatureFlags from "@/helpers/FeatureFlags";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "../../localization/Localizer";
import SetFeatureRequest from "@/models/server/requests/SetFeatureRequest";

import styles from "../Settings/Settings.module.scss";

interface ISettingsProps {
    organizationContracts: OrganizationContract[] | null;
}

interface ISettingsState {
    settingsModified: boolean;
}

export default class Settings extends AuthorizedPage<ISettingsProps, ISettingsState> {

    public getTitle(): string {
        return Localizer.topNavAdmin;
    }

    state: ISettingsState = {
        settingsModified: false,
    };

    private readonly _settingsGridRef: React.RefObject<Grid<Setting>> = React.createRef();

    private readonly _settingsColumns: ColumnDefinition[] = [
        {
            header: Localizer.userManagementSettingsKeyLanguageItemName,
            accessor: "key",
            transform: (cell): string => this.transformKeyCell(cell),
            minWidth: 300,
        },
        {
            header: Localizer.userManagementSettingsValueLanguageItemName,
            accessor: "value",
            editable: true,
            type: ColumnType.Text,
            minWidth: 150,
            callback: async (cell) => await this.onChangeSettingsAsync(cell)
        }
    ];

    private readonly _featureFlagColumns: ColumnDefinition[] = [
        {
            header: Localizer.genericName,
            accessor: model => model,
            type: ColumnType.Text,
            minWidth: 150,
            transform: (cell) => Localizer.get(`FeatureFlags.${cell.model}`),
            init: (cell) => cell.readonly = true,
        },
        {
            header: Localizer.featureFlagManagementFeatureEnabledHeader,
            editable: true,
            accessor: model => UnleashHelper.isEnabled(model),
            type: ColumnType.Boolean,
            minWidth: 50,
            callback: async (cell) => await this.setFeatureAsync(cell.row.model, cell.value)
        }
    ];

    private get settings(): Setting[] {
        return this.settingsGrid.model.items;
    }

    private transformKeyCell(cell: CellModel<Setting>): string {
        const setting: Setting = cell.model;

        const key: string = setting.key;

        return Localizer.get(`KnownSettings.${key}`);
    }

    private async handleSettingsSubmitAsync(): Promise<void> {

        await this.postAsync("api/admin/SaveSettings", this.settings);

        await this.alertMessageAsync(Localizer.userManagementSettingsSaved, true);
        this.setState({ settingsModified: false });
    }

    private async setFeatureAsync(featureName: string, enabled: boolean): Promise<void> {
        const request: SetFeatureRequest = {
            featureName: featureName,
            enabled: enabled,
        };

        await this.postAsync("api/admin/setFeature", request);
    }

    private async getSettingsAsync(grid: Grid<Setting>): Promise<Setting[]> {
        return await grid.getAsync("api/admin/listSettings");
    }

    private async onChangeSettingsAsync(cell: CellModel<unknown>): Promise<void> {
        const settingsModified: boolean = cell.grid.modified;
        await this.setState({settingsModified});
    }

    private get settingsGrid(): Grid<Setting> {
        return this._settingsGridRef.current!;
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.userManagement} fullHeight>
                {
                    (this.isSpinning()) && <Spinner/>
                }

                <PageHeader title={Localizer.userManagementSettingsTab} withTabs/>

                <PageRow>
                    <div className="col">
                        <TabContainer id="settingsTabs" scale>
                            <Tab id="system" title={Localizer.userManagementSettingsTab}>
                                <Form id="form" onSubmit={async () => await this.handleSettingsSubmitAsync()}>
                                    <div>
                                        <Grid id="settings"
                                              ref={this._settingsGridRef}
                                              minWidth={400}
                                              columns={this._settingsColumns}
                                              fetchData={async (sender) => await this.getSettingsAsync(sender)}
                                        />
                                    </div>

                                    <ButtonContainer>
                                        <Button submit
                                                id="saveSettingsButton"
                                                label={Localizer.formSave}
                                                disabled={!this.state.settingsModified}
                                                type={ButtonType.Orange}
                                                icon={{name: "far save"}}
                                        />
                                    </ButtonContainer>
                                </Form>
                            </Tab>

                            <Tab id="featureFlagsTab" title={Localizer.featureFlagManagementTitle}>
                                <Grid
                                    id="featureFlagsGrid"
                                    columns={this._featureFlagColumns}
                                    data={[FeatureFlags.InfoBar, FeatureFlags.ApplicationUpdated]}
                                />
                            </Tab>
                        </TabContainer>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}