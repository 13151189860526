import React from "react";
import {NewsItem} from "@/models/server/NewsItem";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Carousel, CarouselNavigation, CarouselPagination} from "@renta-apps/athenaeum-react-components";

import styles from "./NewsCarousel.module.scss";
import NewsCarouselItem from "@/components/NewsCarousel/NewsCarouselItem";

interface INewsCarouselProps {
    newsItems: NewsItem[];
    className?: string;
}

interface INewsCarouselState {
}

export default class NewsCarousel extends BaseComponent<INewsCarouselProps, INewsCarouselState> {

    public state: INewsCarouselState = {};

    private get hasNewsItems(): boolean {
        return (this.newsItems.length > 0);
    }

    private get newsItems(): NewsItem[] {
        return this.props.newsItems ?? [];
    }

    private get className(): string {
        return this.css(this.props.className, styles.newsCarousel);
    }

    public render(): React.ReactNode {
        if (!this.hasNewsItems) {
            return null;
        }
        return (
            <div id="NewsCarousel">
                <Carousel loop
                          className={this.className}
                          navigation={CarouselNavigation.Inside}
                          pagination={CarouselPagination.BottomInside}
                          slidesPerView={1}
                >
                    {this.newsItems.map((newsItem: NewsItem, index: number) =>
                        <div>
                            <NewsCarouselItem key={index}
                                              newsItem={newsItem}
                                              index={index}
                            />
                        </div>
                        
                    )}
                </Carousel>
            </div>
        );
    }
}