import React from "react";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {CellModel, GridModel, PageContainer, PageRow, RowModel, Spinner} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import WorkOrderModel from "../../models/server/WorkOrderModel";
import Product from "@/models/server/Product";
import WorkOrdersPanel from "@/pages/WorkOrders/WorkOrdersPanel/WorkOrdersPanel";
import CostPool from "@/models/server/CostPool";
import PageDefinitions from "@/providers/PageDefinitions";
import WorkOrdersToolbarModel from "@/pages/WorkOrders/WorkOrdersToolbar/WorkOrdersToolbarModel";
import WorkOrdersToolbar from "@/pages/WorkOrders/WorkOrdersToolbar/WorkOrdersToolbar";
import User from "@/models/server/User";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import Region from "@/models/server/Region";
import UserProvider from "@/providers/UserProvider";
import CostPoolProvider from "@/providers/CostPoolProvider";
import ProductProvider from "@/providers/ProductProvider";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "../../localization/Localizer";
import WorkOrderProvider from "@/providers/WorkOrderProvider";
import RegionsProvider from "@/providers/RegionsProvider";

import styles from "./WorkOrders.module.scss";
import "./BootstrapOverride.scss";

interface IWorkOrdersProps {
}

interface IWorkOrdersState {
    filters: WorkOrdersToolbarModel;
    products: Product[];

    /**
     * Manager users displayed in {@link WorkOrdersToolbar}.
     */
    managers: User[];

    /**
     * Mounter users displayed in {@link WorkOrdersToolbar}.
     */
    mounters: User[];

    /**
     * Regions displayed in {@link WorkOrdersToolbar}.
     */
    regions: Region[];
    costPools: CostPool[];
    managerUserIds: string[];
    mounterUserIds: string[];
}

export default class WorkOrders extends AuthorizedPage<IWorkOrdersProps, IWorkOrdersState> {
    private readonly _filterKey: string = "filter-workOrders-v2"

    public state: IWorkOrdersState = {
        filters: UserInteractionDataStorage.getFilters(new WorkOrdersToolbarModel(), this._filterKey),
        products: [],
        managers: [],
        mounters: [],
        costPools: [],
        regions: [],
        managerUserIds: this.getSavedManagerUserIds(),
        mounterUserIds: this.getSavedMounterUserIds()
    };

    private readonly _workOrdersGridRef: React.RefObject<WorkOrdersPanel> = React.createRef();

    private getSavedManagerUserIds() {
        if (this.userContext.userSpecificFilters) {
            return UserInteractionDataStorage.get("filter-managerUserIds", []);
        }
    }

    private getSavedMounterUserIds() {
        if (this.userContext.userSpecificFilters) {
            return UserInteractionDataStorage.get("filter-mounterUserIds", []);
        }
    }

    private async onFiltersChange(filters: WorkOrdersToolbarModel): Promise<void> {
        UserInteractionDataStorage.setFilters(filters, this._filterKey);

        await this.setSpinnerAsync(true);

        await this.setState({filters});

        await this.workOrdersGrid.reloadAsync();

        await this.setSpinnerAsync(false);
    }

    private async onGetBulkPdf(filters: WorkOrdersToolbarModel): Promise<void> {
        await this.setSpinnerAsync(true);

        const workOrders: WorkOrderModel[] = this._workOrdersGridRef.current!.workOrdersGrid.items;
        
        await WorkOrderProvider.previewBulkWorkOrderPdfAsync(this, workOrders, filters.bulkPdfReceiver);
        
        await this.setSpinnerAsync(false);
    }

    private async addWorkOrderAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.workOrderRoute());
    }

    private async copyWorkOrderAsync(newModel?: WorkOrderModel) {
        const cantAdd: boolean = (this.workOrdersPanel.hasNewRow);

        if (cantAdd) {
            await this.workOrdersGrid.reloadAsync();
        }

        if (!newModel) {
            newModel = new WorkOrderModel();
            newModel.mileagePrice = this.defaultPrices.defaultMileagePrice;
            newModel.hoursPrice = this.defaultPrices.defaultHoursPrice;
        }

        const rows: RowModel<WorkOrderModel>[] = await this.workOrdersGrid.insertAsync(0, newModel!);
        const row: RowModel<WorkOrderModel> = rows[0];
        const nameCell: CellModel<WorkOrderModel> = row.get("name");
        await nameCell.editAsync();

    }

    private get workOrdersGrid(): GridModel<WorkOrderModel> {
        return this.workOrdersPanel.workOrdersGrid;
    }

    private get workOrdersPanel(): WorkOrdersPanel {
        return this._workOrdersGridRef.current!;
    }

    public async initializeAsync(): Promise<void> {
        const [products, costPools, managers, mounters, regions] = await Promise.all([
            ProductProvider.getProductsAsync(this, false),
            CostPoolProvider.getCostPoolsAsync(this),
            UserProvider.getManagersAsync(this),
            UserProvider.getMountersAsync(this, new GetEmployeesRequest()),
            RegionsProvider.getRegionsForUserAsync(),
        ]);

        await this.setState({
            products,
            managers,
            mounters,
            costPools,
            regions,
        });
    }

    public getTitle(): string {
        return Localizer.topNavWorkOrders;
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer scale className={styles.workOrders}>
                {
                    (this.isSpinning()) &&
                    (
                        <Spinner global />
                    )
                }

                <PageRow>
                    <div className={"col w-fit-content"}>
                        <div id="workOrdersToolbar">
                            <WorkOrdersToolbar model={this.state.filters}
                                               userContext={this.userContext}
                                               costPoolFilterEnabled={true}
                                               costPools={this.state.costPools}
                                               managers={this.state.managers}
                                               mounters={this.state.mounters}
                                               regions={this.state.regions}
                                               addWorkOrder={async () => this.addWorkOrderAsync()}
                                               onChange={async (model) => this.onFiltersChange(model)}
                                               onGetBulkPdf={async (model) => this.onGetBulkPdf(model)}
                            />
                        </div>

                        <div className={this.css(styles.main)}>
                            <div className={styles.right}>
                                <WorkOrdersPanel ref={this._workOrdersGridRef}
                                                 filters={this.state.filters}
                                                 defaultPrices={this.defaultPrices}
                                                 copyWorkOrder={async (newModel) => this.copyWorkOrderAsync(newModel)}
                                />
                            </div>
                        </div>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }

}